/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { withSnackbar } from "notistack";
import axios from "axios";
import BlogItem from "../../components/website/elementItem";
import StudentHeader from "../../components/website/studentHeader";
import LinkItem from "../../components/LinkItem";
import { Loader } from "../../components/website/Loader";

class ViewStudentModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      fields: {},
      cms_description: null,
      cms_type: [],
      cms_category: [],
      type: null,
      cms_details: null,
      api_reps: false,
      action: null,
      tableData: null,
      token: localStorage.getItem("admin_token"),
      organization_id: localStorage.getItem("organization_id"),
      admin_name: localStorage.getItem("admin_name"),
      currentCourseBatchId:null
    };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    console.log("id", id);
    
    const batch_id = this.props.match.params.batch_id;

    let updatedBatch;
    if (batch_id === "60790ded6152290d084f744d") {
      updatedBatch = "60dac40b173a6637f4d7ba7c";
    } else {
      updatedBatch = batch_id;
    }
    localStorage.setItem("currentCourseBatchId", updatedBatch);
    this.setState({currentCourseBatchId:updatedBatch})
    this.getAllCMS(id, updatedBatch);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps.match.params.id);
    const id = nextProps.match.params.id;
    
    const batch_id = nextProps.match.params.batch_id;
    let updatedBatch;
    if (batch_id === "60790ded6152290d084f744d") {
      updatedBatch = "60dac40b173a6637f4d7ba7c";
    } else {
      updatedBatch = batch_id;
    }
    localStorage.setItem("currentCourseBatchId", updatedBatch);
    this.setState({currentCourseBatchId:updatedBatch})
    this.getAllCMS(id, updatedBatch);
  }

  async getAllCMS(id, updatedBatch) {
    this.setState({ loading: true });
    axios
      .get(
        process.env.react_app_base_url +
          "/api/v1/modules/moduleByCourse/" +
          id +
          "?status=0&batch_id=" +
          updatedBatch,
      )
      .then((res) => {
        console.log("Resources res", res);
        this.setState({ tableData: res.data.data, loading: false });
      })
      .catch((error) => {
        console.log("error.response.status", error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <StudentHeader></StudentHeader>
        <div className="container">
          <p className="backtoBtn">
            <LinkItem
              to={`/students/my-enrolled-courses/`}
              title={"Back to dashboard"}
            ></LinkItem>
          </p>
        </div>

        <div className="student-dashboard">
          <div className="container">
            <div className="row text-left">
              {this.state.tableData === null
                ?  <Loader></Loader>
                : this.state.tableData.length == 0
                ? "Modules yet not uploaded for this course."
                : " "}
              {this.state.tableData === null
                ? ""
                : this.state.tableData.map((item) => {
                    return (
                      <React.Fragment>
                        <BlogItem
                          key={item._id}
                          image={item.image}
                          title={item.name}
                          short_desc={item.short_description}
                          link={
                            `/students/view-chapter-by-module/${item.name
                              .replace(/\s+/g, "-")
                              .toLowerCase()
                              .replace(",", "")
                              .replace("(", "")
                              .replace(")", "")
                              .replace("/", "-")
                              .replace("!", "")
                              .replace("@", "-")
                              .replace("#", "-")
                              .replace("$", "-")
                              .replace("%", "-")
                              .replace("^", "-")
                              .replace("&", "-")
                              .replace("*", "-")
                              .replace("_", "-")
                              .replace(".", "-")
                              .replace(";", "-")
                              .replace("'", "-")
                              .replace("'", "-")
                              .replace(":", "-")
                              .replace("{", "")
                              .replace("}", "-")
                              .replace("[", "-")
                              .replace("]", "-")
                              .replace("=", "-")
                              .replace("+", "-")
                              .replace("|", "-")
                              .replace(/"/g, "'")
                              .replace(/\\\//g, "")}/${item._id.replace(
                              /\s+/g,
                              "-"
                            )}/${item.cource_name
                              .replace(/\s+/g, "-")
                              .toLowerCase()
                              .replace(",", "")
                              .replace("(", "")
                              .replace(")", "")
                              .replace("/", "-")
                              .replace("!", "")
                              .replace("@", "-")
                              .replace("#", "-")
                              .replace("$", "-")
                              .replace("%", "-")
                              .replace("^", "-")
                              .replace("&", "-")
                              .replace("*", "-")
                              .replace("_", "-")
                              .replace(".", "-")
                              .replace(";", "-")
                              .replace("'", "-")
                              .replace("'", "-")
                              .replace(":", "-")
                              .replace("{", "")
                              .replace("}", "-")
                              .replace("[", "-")
                              .replace("]", "-")
                              .replace("=", "-")
                              .replace("+", "-")
                              .replace("|", "-")
                              .replace(/"/g, "'")
                              .replace(/\\\//g, "")}/${item.cource_id.replace(
                              /\s+/g,
                              "-"
                            )}` + "/"
                          }
                          linkTitle={"Read More"}
                        />
                      </React.Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(ViewStudentModules);
