/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import axios from 'axios';
import { withSnackbar } from 'notistack';

import MUIDataTable from 'mui-datatables';
import IconButton from '@material-ui/core/IconButton';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Button } from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import StudentHeader from '../../components/website/studentHeader';

import Status from "../../components/Status2";
import DateComp from "../../components/DateComp";
import Image from "../../components/Image";

class ViewStudentOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
            cms_type: [],
            cms_category: [],
            action_type: null,
            loading:false,
            cms_type_inline:null,
            token:localStorage.getItem('admin_token'),
            user_id: localStorage.getItem('user_id'),
        };
        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {

        const type = this.props.match.params.title
        this.setState({cms_type_inline:type})
        this.getPaymentDetails();
    }


    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps.match.params.title)
        const type = nextProps.match.params.title;
        this.setState({cms_type_inline:type})
        this.getPaymentDetails();
        // this.getAllCMS(type)
    }


    async getPaymentDetails( ) {
        this.setState({loading:true})

        axios.get(process.env.react_app_base_url + '/api/v1/order/ordersByUserId/'+this.state.user_id, {
            headers: {
                "auth-token": `${this.state.token}`,
            }
        })
            .then(res => {
                console.log("getAllCMS res", res)

                this.setState({ tableData: res.data.data, loading:false })

            })
            .catch(error => {

                console.log("error.response.status", error)
                if (error.response.status === 400) {

                    console.log("API error", error);
                    console.log("error.response", error.response.data.message);

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });

                } else {

                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });

                }
            });
    }

    // payments

    delete(id) {
        console.log("id", id)

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <Button variant="outlined" color="primary" onClick={onClose}>No</Button>
                        <Button variant="contained" color="secondary" onClick={() => {
                            this.handleClickDelete(id);
                            onClose();
                        }}
                        >
                            Yes, Delete it!
                  </Button>
                    </div>
                );
            }
        });
    }

    handleClickDelete(id) {
        console.log(id)

        axios.delete(process.env.react_app_base_url + '/api/v1/centerbatch/' + id, {
            headers: {
                "auth-token": `${this.state.token}`,
            }
        })
            .then(res => {
                console.log("getAllCMS res", res)

                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 5000,
                });

                const type = this.state.cms_type_inline
                this.getAllCMS(type);



            })
            .catch(error => {

                console.log("error.response.status", error)
                if (error.response.status === 400) {

                    console.log("API error", error);
                    console.log("error.response", error.response.data.message);

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });

                } else {

                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });


                }

            });

    }

    async getAllCMS( type) {
        this.setState({loading:true})

        axios.get(process.env.react_app_base_url + '/api/v1/centerbatch?status='+type, {
            headers: {
                "auth-token": `${this.state.token}`,
            }
        })
            .then(res => {
                console.log("getAllCMS res", res)

                this.setState({ tableData: res.data.data, loading:false })

            })
            .catch(error => {

                console.log("error.response.status", error)
                if (error.response.status === 400) {

                    console.log("API error", error);
                    console.log("error.response", error.response.data.message);

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });

                } else {

                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });


                }

            });

    }

    render() {

        const options = {
            filterType: 'dropdown',
            search: true,
            print: false,
            viewColumns: false,
            download: true,
            rowHover: true,
            selectableRowsOnClick: false,
            selectableRows: false,
            // onRowClick: this.onRowClick,
            onRowsSelect: this.onRowsSelect,
            rowsSelected: this.state.rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                console.log("allRows", allRows);
                console.log("rowsSelected", rowsSelected);
                this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
                const selected = allRows.map(row => row.dataIndex);
                console.log("selected" + selected);
                this.selectedpoolid(selected);
            },

            searchText: this.state.searchText,
            searchPlaceholder: 'Your Custom Search Placeholder',
            customSearch: (searchQuery, currentRow, columns) => {
                let isFound = false;
                currentRow.forEach(col => {
                    if (col.toString().indexOf(searchQuery) >= 0) {
                        isFound = true;
                    }
                });
                return isFound;
            },


            loading: true,
            textLabels: {
                body: {
                    noMatch: this.state.loading === false ?
                        'Sorry, there is no matching data to display' :
                        'loading',
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },

                selectedRows: {
                    text: 'row(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Rows',
                },
            },
        };


        const columns = [
            {
                name: 'order_id',
                label: 'Order Id',
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: 'course_name',
                label: 'Course Name',
                options: {
                    filter: true,
                    sort: true,
                },
            },

            {
                name: 'batch_name',
                label: 'Batch Name',
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: 'plan_name',
                label: 'Plan',
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                name: 'razorpay_signature',
                label: 'Payment Mode',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
    {value?.length === 0 ? '-' : value === 'Offline' ? 'Offline':'Online'}
                            {/* {JSON.stringify(value)} */}
                            </>

                        );
                    }
                }
            },
            
            {
                name: 'status',
                label: 'Payment Status',
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Status status={value}></Status>
                        );
                    }
                }
            },


            {
                name: 'amount',
                label: 'Amount',
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                               {value}
                            </React.Fragment>
                        );

                    },
                },
            },
            {
                name: 'created_on',
                label: 'Created Date',
                options: {
                    filter: false,
                    sort: false,
                    sortDirection: 'desc',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <DateComp date={value}></DateComp>
                        )
                    }

                },
            },
            {
                name: "_id",
                label: 'Action',
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                
                                
                                {/* <NavLink to={"/admin/add-module/view/"+ value}>  </NavLink> */}
                                <IconButton disabled color="primary" aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true">
                                    <VisibilityOutlinedIcon></VisibilityOutlinedIcon>
                                </IconButton>
                               

                            </React.Fragment>
                        );
                    },
                }
            },

            
        ];
        const pagetitle = "View Orders"
        return (
            <React.Fragment>
                <StudentHeader></StudentHeader>
                <div className="student-dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <React.Fragment>
                                    <MUIDataTable
                                        title={pagetitle}
                                        data={this.state.tableData}
                                        columns={columns}
                                        options={options}
                                    />
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(ViewStudentOrder);
