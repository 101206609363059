/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
            redirect: props.redirect,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleLangChange = this.handleLangChange.bind(this);

    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log("fields", this.state.fields);
    }

    login = () => {
        const formData = new FormData();
        formData.append("email", this.state.fields.email);
        formData.append("password", this.state.fields.password);
        formData.append("user_type", 'Student');

        console.log("Login", JSON.stringify(formData));

        axios
            .post(process.env.react_app_base_url + "/api/v1/user/login", formData)
            .then((res) => {
                console.log("res", res);
                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });

                const resp = res.data;
                localStorage.setItem("user_token", resp.token);
                localStorage.setItem("user_email", resp.email);
                localStorage.setItem("user_name", resp.name);
                localStorage.setItem("user_mobile", resp.mobile);
                localStorage.setItem("user_id", resp.id);
                localStorage.setItem("user_user_type", resp.user_type);

                if (this.state.redirect == undefined) {

                    console.log("no redirect")
                    this.handleLangChange(resp.id)

                } else {

                    this.props.history.push({
                        pathname: this.state.redirect,
                    });

                }
            })
            .catch((error) => {
                console.log("res", error);
                if (error.response.status === 400) {
                    console.log("API error", error);
                    console.log("error.response", error.response.data.messages);

                    const message = error.response.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                } else {
                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                }
            });
    };


    handleLangChange(user_id) {
        this.props.onSelectLanguage(user_id);
    }


    render() {

        return (
            <React.Fragment>

                <div className="adminLogin">
                    <div className="loginForm2">
                        <p>Welcome Back !</p>
                        <p>Sign in to continue to VCTC Pune  Students Panel</p>
                        <div className="mb-3">
                            <TextField
                                name="email"
                                size="medium"
                                value={this.state.fields.email}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Email  Id"
                                variant="outlined"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                name="password"
                                size="medium"
                                type="password"
                                value={this.state.fields.password}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                            />
                        </div>

                        <div className="mb-3">
                            <Button
                                variant="contained"
                                size="large"
                                className="display-block"
                                color="primary"
                                onClick={this.login}
                            >
                                {" "}
                Submit{" "}
                            </Button>
                        </div>

                        <div className="hide-on-popup">
                            <p> <NavLink className="smalltext" to="/forgot-password/">Forgot your password?</NavLink></p>
                            <h5 className="divider"><span>New to VCTC Pune  Account?</span></h5>
                            <NavLink className="smalltext" to="/signup/"><Button variant="outlined" color="primary">Sign Up</Button></NavLink>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withSnackbar(Login));


