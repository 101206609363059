/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import ReactModal from 'react-modal';
import Button from '@material-ui/core/Button';
import DefaultImage from '../../images/placeholder.jpg';
import DefaultImageResource from '../../images/default-free-resource.jpg';
import DownloadResource from './downloadResource';
import moment from 'moment';

export default class BlogItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

    }

    handleOpenModal(data) {
        console.log("data" + data)
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <React.Fragment>
                {/* "" */}
                {/* {JSON.stringify(this.props)} */}
                <div className={this.props.type === 'blog_home' ? 'col-12 col-sm-6 col-md-12 col-lg-12 text-left elementGab mb-3' : this.props.type === 'download' ? 'col-12 col-sm-6 col-md-3 col-lg-3 text-left elementGab mb-3' : 'col-12 col-sm-6 col-md-3 col-lg-4 text-left elementGab mb-3'} >
                    <div className="blogItem innerbox">
                        <div className="image">

                            {this.props.type === undefined ?
                                <span className="date">

                                    {this.props.cms_publish_date === 'null' ? moment(this.props.date).format('D/M/yyyy') : this.props.cms_publish_date}

                                </span> :

                                this.props.type === 'blog_home' ?
                                    <React.Fragment>
                                        {this.props.date !== null ?
                                            <span className="date">{this.props.cms_publish_date === 'null' ? moment(this.props.date).format('D/M/yyyy') : this.props.cms_publish_date}</span>
                                            : ''}

                                    </React.Fragment>


                                    :
                                    <React.Fragment>
                                        <span>{this.props.course_name}</span>
                                    </React.Fragment>
                            }




                            {this.props.type === 'download' ?
                                <React.Fragment>
                                    <div className="freeimage">
                                        <div className="title"> {this.props.title} </div>
                                        <img src={DefaultImageResource} alt="" />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {this.props.image === "null" ?
                                        <img src={DefaultImage} alt="" /> :
                                        this.props.image === null ? <img src={DefaultImage} alt="" /> :
                                            <img src={process.env.react_app_base_url + "/" + this.props.image} alt="" />
                                    }
                                </React.Fragment>
                            }

                        </div>
                        <div className="textWrapper">
                            <h3>{this.props.title}</h3>
                            {this.props.short_desc && <div className="short_desc">{this.props.short_desc}</div>}

                            {this.props.type === undefined ? <LinkItem className="course_btn" to={this.props.link} fontIcon={"ti-arrow-right"} title={this.props.linkTitle}></LinkItem> :

                                this.props.type === 'blog_home' ?

                                    <LinkItem className="course_btn" to={this.props.link} fontIcon={"ti-arrow-right"} title={this.props.linkTitle}></LinkItem>
                                    :
                                    <React.Fragment>

                                        {this.props.userLogged === null ?
                                            <ul className="singup-list">
                                                <li><LinkItem variant="primary" to={"/students/free-resouces/" + this.props.link + "/" + this.props.id} title={'View Video'}></LinkItem></li>
                                                <div className="clearfix"></div>
                                            </ul>
                                            :
                                            <ul className="singup-list">
                                                <li><LinkItem variant="primary" to={"/students/free-resouces/" + this.props.link + "/" + this.props.id} title={'View Video'}></LinkItem></li>

                                                <div className="clearfix"></div>
                                            </ul>
                                        }
                                    </React.Fragment>
                            }

                        </div>
                    </div>
                </div>


                {this.props.type === undefined ? '' :
                    <ReactModal
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"

                        className="Modal"
                        overlayClassName="Overlay"
                    >
                        <div className="popup">
                            <Button className="closeBtn" color="primary" variant="outlined" onClick={this.handleCloseModal}>
                                <span className="ti-close iconSpan"></span> Close
                            </Button>
                            <DownloadResource
                                id={this.props.id}
                                course_name={this.props.course_name}
                                title={this.props.title}
                            >
                            </DownloadResource>
                        </div>
                    </ReactModal>

                }

            </React.Fragment>
        );
    }
}
