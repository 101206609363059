/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubBannerNew from '../../images/SubBannerNew.png'

import BannerJava from '../../images/BannerJava.png';
import bannerDataScience from '../../images/bannerDataScience.png';
import BannerSoftwareTesting from '../../images/BannerSoftwareTesting.png';
import BannerAWS from '../../images/BannerAWS.png';
import BannerSalesForce from '../../images/BannerSalesForce.png';
import BannerOtherCourses from '../../images/BannerOtherCourses.png';
import BannerAboutUs from '../../images/BannerAboutUs.png';

import BannerUpcomingBatch from '../../images/BannerUpcomingBatch.png';
import BannerContact from '../../images/BannerContact.png';
import BannerCourses from '../../images/BannerCourses.png';
import BannerTestimonials from '../../images/BannerTestimonials.png';

import TermsConditions from '../../images/TermsConditions.png';
import PrivacyPolicy from '../../images/PrivacyPolicy.png';
import RefundPolicy from '../../images/RefundPolicy.png';

import { useHistory } from 'react-router-dom';

const SubHeader = ({
    image
}) => {
    const history = useHistory();
    console.log("history?.location?.pathname", history?.location?.pathname)
    return (
        <React.Fragment>
            <div className='sliderWrapper'>
                <div className='sliderHome'>
                    <div className='imageHolder'>
                        {image ? <img src={image} alt="" title="" /> :
                            <img src={history?.location?.pathname.includes("about-us") ? BannerAboutUs :
                                history?.location?.pathname.includes("data-science") ? bannerDataScience :
                                    history?.location?.pathname.includes("software-testing") ? BannerSoftwareTesting :
                                        history?.location?.pathname.includes("java-development") ? BannerJava :
                                            history?.location?.pathname.includes("aws-devops") ? BannerAWS :
                                                history?.location?.pathname.includes("salesforce") ? BannerSalesForce :
                                                    history?.location?.pathname.includes("upcoming-batches") ? BannerUpcomingBatch :
                                                        history?.location?.pathname.includes("contact-us") ? BannerContact :
                                                            history?.location?.pathname.includes("certificate-courses") ? BannerCourses :
                                                                history?.location?.pathname.includes("testimonials") ? BannerTestimonials :

                                                                    history?.location?.pathname.includes("terms-conditions") ? TermsConditions :
                                                                        history?.location?.pathname.includes("privacy-policy") ? PrivacyPolicy :
                                                                            history?.location?.pathname.includes("return-refund-policy") ? RefundPolicy :

                                                                                history?.location?.pathname.includes("other-courses") ? BannerOtherCourses : SubBannerNew} alt="" title="" />

                        }
                    </div>
                    <div className='sliderText2'>
                        {/* <h1>{this.props.title}</h1> */}
                        {/* {this.props.para && <p>{this.props.para}</p>} */}
                    </div>
                </div>
                <div className='slideBtn'></div>
            </div>
        </React.Fragment>
    );

}

export default SubHeader