import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ContactForm from './contactForm';
import ImageRefer from '../../images/Refer-&-Earn.jpg'
import { NavLink } from 'react-router-dom';

const Popup = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const handleOpen = () => {
        const timer = setTimeout(() => {
            setOpen(true);
            sessionStorage.setItem("popup", JSON.stringify(true))
        }, 1000)
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        let sessionValue = sessionStorage.getItem('popup')
        console.log("sessionValue", sessionValue)
        if (sessionValue !== 'true')
            handleOpen();
    }, [])



    return (
        <div>
            <Modal open={open} onClose={onCloseModal} center>
                <div className='contactFormPopup'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h2>Get in touch with us</h2>
                            <ContactForm></ContactForm>
                        </div>
                        <div className='col-sm-6'>
                            <div className='referProgram'>
                                <NavLink onClick={onCloseModal} to={'/cms/refer-earn/64436c340bd0df521f57f183'}>
                                    <img src={ImageRefer} alt='' title='' />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Popup