/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import axios from "axios";

import { Loader } from '../../components/website/Loader';
import PhoneInput from 'react-phone-input-2';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
class CMSPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: null,
            cmsId: null,
            loading: true,
            pageData: null,
            fields: {},
            phone: '',
            fphone: '',
            organization_id: '5f048db166e1c93c780e7e7c',
            discount_table: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const pageTitle = this.props.match.params.pageTitle;
        const cmsId = this.props.match.params.cmsId;
        this.setState({
            pageTitle: pageTitle,
            cmsId: cmsId,
        })
        this.getAllCMS(cmsId);
        this.getAllCMS2()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ loading: true })
        const fields = {
            batch_name: '',
            plan_name: '',
        }

        this.setState({ fields: fields })

        console.log("nextProps", nextProps.match.params.title)
        console.log("nextProps", nextProps.match.params.cmsId)
        const id = nextProps.match.params.cmsId
        this.getAllCMS(id);
    }

    async getAllCMS(cmsId) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages/' + cmsId + "?status=0")
            .then(res => {
                console.log("CMS Pages res", res)
                this.setState({ pageData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });
        console.log('handleChange', this.state.fields);
    }

    Subscribe = () => {
        if (this.state.fields.name && this.state.fields.course_name && this.state.fields.email && this.state.phone) {
            const formData = new FormData();
            formData.append('organization_id', this.state.organization_id);
            formData.append('name', this.state.fields.name);
            formData.append('course_name', this.state.fields.course_name);
            formData.append('email', this.state.fields.email);
            formData.append('mobile', this.state.phone);
            formData.append('fname', this.state.fields.fname);
            formData.append('fphone', this.state.fphone);
            formData.append('status', '1');
            formData.append('created_by', 'Website');
            console.log(JSON.stringify(formData));
            axios.post(process.env.react_app_base_url + '/api/v1/frontend/refer', formData)
                .then(res => {
                    console.log("res", res)
                    const message = res.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: 'info',
                        autoHideDuration: 5000,
                    });
                    this.props.history.push("/thank-you/")
                    this.setState({
                        fields: {
                            "name": '',
                            "email": '',
                            "mobile": '',
                        },
                        phone: ''
                    })
                })
                .catch(error => {
                    console.log("error.response.status", error)
                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                });
        } else {
            this.props.enqueueSnackbar('Please enter the all details', {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }

    async getAllCMS2(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0&sortBy=order_id=-1')
            .then(res => {
                console.log("Courses res", res)
                const cfa_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)
                const other_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course !== "CFA");
                console.log("other_menu", other_menu)
                this.setState({ cfa_menu: cfa_menu, discount_table: other_menu, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading == true ? <Loader></Loader> :
                    this.state.pageData !== 'null' ?
                        <React.Fragment>

                            <SubHeader image={this.state.pageData?.image !== "null" ? process.env.react_app_base_url + "/" + this.state.pageData?.image : undefined} title={this.state.pageData?.title}></SubHeader>
                            <div className="section aboutus">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-8 col-sm-8 text-left CMSPages">
                                            {/* {this.state.pageData?.image == 'null' ?
                                                '' :
                                                <div className="imagePlaceholder">
                                                    <img src={process.env.react_app_base_url+"/"+this.state.pageData?.image} />
                                                </div>
                                            } */}
                                            <div dangerouslySetInnerHTML={{ __html: this.state.pageData?.description }}></div>
                                        </div>

                                        {/* {this.state.cmsId === '64436c340bd0df521f57f183' &&
                                            <div className="col-md-4 col-sm-4 text-left">
                                                <div className='innerHTML'>
                                                    <p>Fill the form</p>
                                                    <p><label>Referrer Name</label>
                                                        <TextField name="name" size="small" value={this.state.fields.name} onChange={this.handleChange} id="outlined-basic" variant="outlined" /></p>
                                                    <p><label>Referrer Email Id</label><TextField name="email" size="small" value={this.state.fields.email} onChange={this.handleChange} id="outlined-basic" label="" variant="outlined" /></p>
                                                    <p>
                                                        <label>Referrer Course</label>
                                                        <select onChange={this.handleChange} name="course_name" className='form-control'>
                                                            <option value=''>Select Course</option>
                                                            {this.state.discount_table?.map((item) => {
                                                                console.log("item", item?.name)
                                                                return (
                                                                    <option value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })
                                                            }
                                                        </select>
                                                    </p>
                                                    <p>
                                                        <label>Referrer Phone</label>
                                                        <PhoneInput
                                                            country={'in'}
                                                            value={this.state.phone}
                                                            onChange={phone => this.setState({ phone })}
                                                        />
                                                    </p>
                                                    <p>
                                                        <label>Friends / Relatives Name</label>
                                                        <TextField name="fname" size="small" value={this.state.fields.fname} onChange={this.handleChange} id="outlined-basic" variant="outlined" /></p>

                                                    <p>
                                                        <label>Friends / Relatives Phone</label>
                                                        <PhoneInput
                                                            country={'in'}
                                                            value={this.state.fphone}
                                                            onChange={fphone => this.setState({ fphone })}
                                                        />
                                                    </p>
                                                    <p><Button onClick={this.Subscribe} variant="contained" color="primary">Submit</Button></p>
                                                </div>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        : ''
                }
            </React.Fragment>
        );
    }
}

export default withSnackbar(CMSPages);
