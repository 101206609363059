/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import CourseImage from '../../images/course_image.jpg'
import BlogItem from './elementItem';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
export default class FreeResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }

    async componentDidMount() {

        this.getAllCMS('Resources');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("Resources res", res)

                const filteredDates = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
                console.log("filteredDates", filteredDates)

                this.setState({ tableData: filteredDates, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)


            });

    }

    render() {
        return (
            <div className="blog freeresources">
                <h2 className="">
                    <NavLink to="/free-resources"> Free Resources</NavLink>
                </h2>
                {this.state.tableData === null ? '' : this.state.tableData.slice(0, 8).map(item => {
                    return (
                        <p> <NavLink to={`/students/free-resouces/${item.cms_link}/${item._id}`}> {item.cms_title}</NavLink> </p>
                        
                    )
                })
                }
            </div>
        );
    }
}
