/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import LoginInner from '../../components/website/loginInner';
import axios from "axios";

import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { NavLink } from 'react-router-dom';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
            redirect: "/students/dashboard/",
            email_token:null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log("fields", this.state.fields);
    }

    componentDidMount() {


        const query = new URLSearchParams(this.props.location.search);
        const email_token = query.get('email_token')
        console.log("email_token", email_token)
        if (email_token == null) {

            const message = "Email Id Missing !!!";
            this.props.enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
            });

        } else {

            this.setState({email_token:email_token})
        }

        localStorage.removeItem("user_token")
        localStorage.removeItem("user_email")
        localStorage.removeItem("user_name")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_user_type")

    }

    login = () => {

        if(this.state.fields.password !== this.state.fields.confirmpassword){

            const message = "Password & Confirm Password is not same";
            this.props.enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
            });
          
        } else {

        const formData = {
                "email_token": this.state.email_token,
                "password": this.state.fields.password
        }
        
        axios
            .post(process.env.react_app_base_url + "/api/v1/user/student/changepassword", formData)
            .then((res) => {
                console.log("res", res);
                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });
            })
            .catch((error) => {
                console.log("res", error);
                if (error.response.status === 400) {
                    console.log("API error", error);
                    console.log("error.response", error.response.data.messages);

                    const message = error.response.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                } else {
                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                }
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Change Password"></SubHeader>
                <div className="adminLogin">
                    <div className="loginForm2">
                        <p>Change Password</p>
                        <p>Please enter new password and confirm password</p>

                        <div className="mb-3">
                            <TextField
                                name="password"
                                type="password"
                                size="medium"
                                value={this.state.fields.password}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                            />
                        </div>

                        <div className="mb-3">
                            <TextField
                                name="confirmpassword"
                                size="medium"
                                type="password"
                                value={this.state.fields.confirmpassword}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Confirm Password"
                                variant="outlined"
                            />
                        </div>


                        <div className="mb-3">
                            <Button
                                variant="contained"
                                size="large"
                                className="display-block"
                                color="primary"
                                onClick={this.login}
                            >
                                {" "}
                Submit{" "}
                            </Button>
                        </div>
                        <p>
                            <NavLink className="smalltext" to="/login/">Login</NavLink></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(ChangePassword);
