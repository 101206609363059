/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { withSnackbar } from 'notistack';
import axios from 'axios';
import BlogItem from '../../components/website/elementItem';
import StudentHeader from '../../components/website/studentHeader';
import LinkItem from '../../components/LinkItem';
import ReactModal from 'react-modal';
import Button from '@material-ui/core/Button';
import DownloadImg from '../../images/download.png';
import viewDownload from '../../images/viewDownload.png';

import moment from 'moment';
import CourseItem from '../../components/website/courseItem';
import Placeholder from '../../images/placeholder.jpg'
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/website/Loader";

class StudentsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            cms_description: null,
            cms_type: [],
            cms_category: [],
            type: null,
            cms_details: null,
            api_reps: false,
            cms_description: null,
            action: null,
            tableDataCourses: null,
            tableMyDataCourses: null,
            tableData: null,
            user_token: localStorage.getItem('user_token'),
            user_email: localStorage.getItem('user_email'),
            organization_id: localStorage.getItem('organization_id'),
            user_name: localStorage.getItem('user_name'),
            user_id: localStorage.getItem('user_id')
        };
    }

    async componentDidMount() {

        this.getAllCMS('Resources');
        this.getAllCourses();
        this.getMyCourses();

    }

    async getAllCourses() {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0&sortBy=order_id=-1')
            .then(res => {
                console.log("Courses res", res)
                this.setState({ tableDataCourses: res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getMyCourses() {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/order/getEnrolledByUserId/' + this.state.user_id, {
            headers: {
                "auth-token": `${this.state.user_token}`,
            }
        })
            .then(res => {
                console.log("getMyCourses res", res.data.data)
                this.setState({ tableMyDataCourses: res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)), loading: false })
                // console.log("course_details", course_details)
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }


    async getAllCMS(type) {

        this.setState({ loading: true })

        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("Resources res", res)

                const filteredDates = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
                console.log("filteredDates", filteredDates)

                this.setState({ tableData: filteredDates, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }





    render() {
        return (
            <React.Fragment>
                <StudentHeader></StudentHeader>
                <div className="student-dashboard">
                    <div className="container">
                        <div className="row text-left">
                            <div className="col-md-12">
                                <div class="card mb-3">
                                    <div class="card-header bg-secondary">
                                        <h3>My Enrolled Courses <LinkItem variant="primary" to={"/students/my-enrolled-courses/"} title={'View All'}></LinkItem> </h3>
                                    </div>
                                    <div class="card-body" style={{ padding: '0px' }}>
                                        <div className="admin-list">
                                            <table className="table table-bordered">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th> Course Name / Batch Name </th>
                                                        <th> Action</th>
                                                    </tr>
                                                </thead>
                                                {this.state.tableMyDataCourses === null ? <Loader></Loader> : this.state.tableMyDataCourses.slice(0, 2).map(item => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <strong>{item.course_name}</strong> <br></br>
                                                                {item.batch_name} </td>
                                                            <td style={{ width: "150px" }}> <LinkItem className="view-details" variant="primary"
                                                                to={`/students/view-modules-by-course/${item.course_name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item.course_id.replace(/\s+/g, '-')}` + "/" + item.batch_id + "/"}
                                                                title={'View Details'}></LinkItem></td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </table>

                                            {this.state.tableMyDataCourses === null ? '' : this.state.tableMyDataCourses.length !== 0 ? '' : 'You have not buy any course.'}

                                        </div>
                                    </div>
                                </div>





                            </div>


                            <div className="col-md-6">
                                <div class="card mb-3">
                                    <div class="card-header bg-secondary">
                                        <h3>Download Course Curriculum </h3>
                                    </div>
                                    <div class="card-body" style={{ padding: '0px' }}>
                                        <div className="admin-list">
                                            <table className="table table-bordered">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th> Course Name </th>
                                                        <th> Download</th>
                                                    </tr>
                                                </thead>
                                                {this.state.tableDataCourses === null ? '' : this.state.tableDataCourses.map(item => {
                                                    return (
                                                        <React.Fragment>

                                                            <tr>
                                                                <td>
                                                                    <strong>{item.name}</strong> </td>
                                                                <td style={{ width: "100px", textAlign: "center" }}>

                                                                    {item.attachment == null ? '' : <a download={process.env.react_app_base_url + "/" + item.attachment} href={process.env.react_app_base_url + "/" + item.attachment} target="_blank">
                                                                        <img src={DownloadImg} title="" alt="" />
                                                                    </a>}

                                                                </td>
                                                            </tr>

                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </table>


                                        </div>
                                    </div>
                                </div>
                            </div><div className="col-md-6">
                                <div class="card mb-3">
                                    <div class="card-header bg-secondary"><h3>Free Resources
                                        <LinkItem variant="primary" to={"/students/free-resouces/"} title={'View All'}></LinkItem>
                                    </h3></div>
                                    <div class="card-body" style={{ padding: "0px" }}>
                                        <div className="admin-list">
                                            <table className="table table-bordered">
                                                {this.state.tableData === null ? '' : this.state.tableData.slice(0, 6).map(item => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td style={{ width: "100px", textAlign: "center" }}>
                                                                    <NavLink to={"/students/free-resouces/" + this.props.link + "/" + this.props.id}>
                                                                        <img src={viewDownload} title="" alt="" />
                                                                    </NavLink>
                                                                </td>
                                                                <td style={{ verticalAlign: "middle" }}>
                                                                    <NavLink className={"font-weight-bold"} to={"/students/free-resouces/" + item?.cms_link + "/" + item?._id}>
                                                                        {item.cms_title}
                                                                    </NavLink>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>




                        </div>

                    </div>


                </div>

                <div id="dashboard-courses">
                    <div className="container">
                        <div className="coursesComp">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="">Our Courses</h2>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.tableDataCourses === null ? '' : this.state.tableDataCourses.map(item => {
                                    return (
                                        <React.Fragment>
                                            <div className="col-md-4 mb-3">
                                                <div className='inner'>
                                                    <div className="image" style={{ background: `url(${process.env.react_app_base_url + "/" + item.image})` }}>
                                                    </div>
                                                    <h3>
                                                        <LinkItem title={item.name} to={`/courses/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"}>
                                                        </LinkItem></h3>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(StudentsDashboard);
