/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeholder from '../../images/placeholder.jpg'
import user_placeholder from '../../images/logo2.jpg';
import { Loader } from './Loader';

export default class Placement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        };
    }


    async componentDidMount() {

        this.getAllCMS('Placement');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Placement res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            lazyLoad: true,
            autoplay: true,
            autoplaySpeed: 8000
        };
        return (
            <React.Fragment>
                <div className='placementCompanies'>
                    <div className='wrapper'>
                        <h3>Our alumni work at reputed tech organizations</h3>
                        <div>
                            <Slider {...settings}>
                                {this.state.tableData === null ? <Loader></Loader> : this.state.tableData.map((item, index) => {
                                    return (
                                        <>
                                            <div className='itemPlacement'> {item.cms_image === 'null' ? <img src={user_placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />} </div>
                                        </>
                                    )
                                })
                                }
                            </Slider>

                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
