/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import moment from 'moment';
import CommentIcon from '@material-ui/icons/Comment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';


class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                user_id: localStorage.getItem('user_id') || null,
                user_name: localStorage.getItem('user_name') || null,
                user_email: localStorage.getItem('user_email') || null,
                user_comments: '',
            },
            tableData: null,
            organization_id: '5f048db166e1c93c780e7e7c',
            cms_title: this.props.cms_title,
            cms_id: this.props.cms_id,

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }

    componentDidMount() {

        const id = this.state.cms_id
        this.getAllCMS(id)
    }


    async getAllCMS(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/comments/byPostId/' + id)
            .then(res => {
                console.log("Courses res", res)
                const filteredDates = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
                console.log("filteredDates", filteredDates)
                const likeLength = res.data.data.length;
                this.props.action(likeLength);
                this.setState({ tableData: filteredDates, loading: false });
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }



    AddComments = () => {

        const formData = new FormData();
        formData.append('user_id', this.state.fields.user_id);
        formData.append('organization_id', this.state.organization_id);
        formData.append('user_name', this.state.fields.user_name);
        formData.append('user_email', this.state.fields.user_email);
        formData.append('user_comments', this.state.fields.user_comments);
        formData.append('post_id', this.state.cms_id);
        formData.append('post_name', this.state.cms_title);
        formData.append('status', '1');

        console.log(JSON.stringify(formData));

        axios.post(process.env.react_app_base_url + '/api/v1/comments', formData)
            .then(res => {
                console.log("res", res)
                const message = res.data.messages;

                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 7000,
                });

                this.getAllCMS(this.state.cms_id)

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });

    }

    render() {
        return (
            <React.Fragment>

                <div className="comments-list" id="comments">
                    <h4><CommentIcon></CommentIcon> Comments</h4>

                    <div className="list">
                        {this.state.tableData == null ? '' :
                            this.state.tableData.map(item => {
                                return (
                                    <React.Fragment>
                                        <div className="item">
                                            <p><strong>{item.user_name}</strong> <span>{moment(item.created_on, "YYYYMMDD").fromNow()}</span></p>
                                            <div className="comment-box">
                                                {item.user_comments}


                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <p>
                            {this.state.tableData == null ? '' : this.state.tableData.length == 0 ? 'No Comments' : ' '}
                        </p>
                    </div>

                    <div className="add-response">
                        <h4><CommentIcon></CommentIcon>Add Comments</h4>
                        <p><TextField name="user_name" size="small" value={this.state.fields.user_name} onChange={this.handleChange} id="outlined-basic" label="Name" variant="outlined" /></p>
                        <p><TextField name="user_email" size="small" value={this.state.fields.user_email} onChange={this.handleChange} id="outlined-basic" label="Email Id" variant="outlined" /></p>
                        <p><TextField name="user_comments" multiline rows="4" size="small" value={this.state.fields.user_comments} onChange={this.handleChange} id="outlined-basic" label="Comments" variant="outlined" /></p>
                        <p><Button onClick={this.AddComments} variant="contained" color="primary"> Comments </Button></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(Comments);
