import * as React from 'react';
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

export default function LinkItem(props) {
  return (
    <Tooltip title={props.title}>
      <NavLink activeClassName="navbar-active" className={props.className === undefined ? 'nav-link' : props.className + ' nav-link'}
        to={{
          pathname: props.to,
          search: "?sort=name",
          hash: "#the-hash",
          state: { previous_url: props.to }
        }}
      >
        <Button aria-controls="simple-menu" aria-haspopup="true">
          {props.icon === undefined ? '' : <span className="icon">
            <img alt={props.title} title={props.title} src={props.icon} />
          </span>
          }
          {props.fontIcon === undefined ? ' ' : <span className={'fontIcon ' + props.fontIcon}></span>}
          {props.step === undefined ? ' ' : <span className="step">
            {props.step}
          </span>}
          <span className="text">
            {props.title}
          </span>
        </Button>
        {props.children}
      </NavLink>
    </Tooltip>
  );
}
