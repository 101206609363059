/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { withRouter } from "react-router";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}



const __DEV__ = document.domain === 'localhost';

class CareerForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			phone: '',
			organization_id: '5f048db166e1c93c780e7e7c',
			startdate: ""
		};
		this.handleChange = this.handleChange.bind(this);

	}

	handleChange(e) {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});

		console.log('fields', this.state.fields);

	}


	setStartDate = (data) => {
		// alert(data)
		console.log("setStartDate", data)
		this.setState({ startdate: data })
	}


	GetTouch = async () => {

		this.props.history.push("/contact-us");

	}

	Subscribe = async () => {
		if (this.state.phone.length < 8) {
			alert("Please enter the valid mobile number")
		} else {
			const formData = new FormData();
			formData.append('organization_id', this.state.organization_id);
			formData.append('name', this.state.fields.name);
			formData.append('email', this.state.fields.email);
			formData.append('mobile', this.state.phone);

			formData.append('date', this.state.startdate);
			formData.append('fees', '1500');

			formData.append('status', '1');
			formData.append('created_by', 'Website');

			console.log(JSON.stringify(formData));
			// alert(this.state.user_id)

			const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

			if (!res) {
				alert('Razorpay SDK failed to load. Are you online?')
				return
			}

			let inputdata = {
				course_id: "Career Counselling for BFSI",
				course_name: "Career Counselling for BFSI",
				plan_name: "Career Counselling for BFSI",
				user_name: this.state.fields.name,
				email_id: this.state.fields.email,
				phone: this.state.phone,
				date: this.state.startdate,
				created_by: this.state.fields.name,
				organization_id: this.state.organization_id,
				status: "1",
			}

			try {
				const res = await axios.post(process.env.react_app_base_url + '/api/v1/order/CareerPlaceOrder', inputdata);
				const resp = res.data;
				if (res.status === 200) {
					console.log('CareerPlaceOrder', resp);

					const options = {
						key: __DEV__ ? 'rzp_live_fqkkZuLz2e3L4L' : 'rzp_live_fqkkZuLz2e3L4L',
						currency: resp.currency,
						amount: parseInt(resp.amount),
						order_id: resp.order_id,
						name: resp.course_name,
						description: "Career Counselling for BFSI",
						image: process.env.react_app_base_url_app + '/logo.jpg',
						handler: async function (response) {
							console.log("response response response response response response response ", response)
							const paymentDetails = {
								"payment_id": response.razorpay_payment_id,
								"order_id": response.razorpay_order_id,
								"razorpay_signature": response.razorpay_signature,
							}

							const patchData = {
								"id": resp.id,
								"payment_id": response.razorpay_payment_id,
								"order_id": response.razorpay_order_id,
								"razorpay_signature": response.razorpay_signature
							}

							try {
								const respPatch = await axios.patch(process.env.react_app_base_url + '/api/v1/order/CareerPlaceOrder', patchData);
								if (respPatch.status === 200) {
									alert("We have received payment. Your order in progress, you can check the status in My Account - Payment details section.");
									window.location.assign(process.env.react_app_base_url_app + "/thank-you/")
									// this.props.history.push(process.env.react_app_base_url_app+"/students/thank-you-purchase/")
								}
							} catch (error) {
								alert('something went wrong, please try again');

							}

						},
						prefill: {
							name: this.state.fields.name,
							email: this.state.fields.email,
							contact: this.state.phone,
						}
					}

					const paymentObject = new window.Razorpay(options)
					paymentObject.open()


				} else {
					alert('Failed');


				}

			} catch (error) {
				alert('Failed');
				console.log('placeorder API error', error.response.data.messages);

				const message = error.response.data.messages;
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 7000,
				});
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="careerForm">
					<p>
						<label> Name </label>
						<TextField name="name" size="small" value={this.state.fields.name} onChange={this.handleChange} id="outlined-basic" variant="outlined" />
					</p>

					<p>
						<label> Email Address </label>
						<TextField name="email" size="small" value={this.state.fields.email} onChange={this.handleChange} id="outlined-basic" variant="outlined" />
					</p>

					<p>
						<label> Preferred Meeting Date </label>
						<DatePicker
							selected={this.state.startdate}
							onChange={date => this.setStartDate(date)}
						/>
					</p>

					<p>
						<label> Phone Number </label>
						<PhoneInput
							country={'in'}
							value={this.state.phone}
							onChange={phone => this.setState({ phone })}
						/>
					</p>



					<p className=''><Button onClick={this.Subscribe} variant="contained" color="primary"> Pay & Confirm Meeting </Button></p>
						<ul>
						<li>Our one-on-one discussions with</li>
						<li>Candidates can be done over</li>
						<li>Zoom meetings / Telephone</li>
						</ul>
						
					
					<h3 className=''>How does this work?</h3>
					<p className=''>We provide a customized career guidance service that is tailor-made to the profile and career aspirations of the candidate. We follow a systematic approach which covers the complete life cycle, in a nutshell -initial questionnaire followed by one-on-one discussions with the candidate and finally our recommendations and advice!</p>
					<p className=''><Button onClick={this.GetTouch} variant="outlined" color="primary"> Get in Touch </Button></p>
				</div>
			</React.Fragment>
		);
	}
}


export default withRouter(withSnackbar(CareerForm));