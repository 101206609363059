/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import Button from "@material-ui/core/Button";

export default class SocialIcons extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <ul className="social_icons">
                    {/* <li><LinkItem to="/courses" fontIcon="ti-facebook" title=""></LinkItem> </li> */}
                    <li><a target="_blank"  href="https://www.linkedin.com/in/ameya-abhyankar-cfa-cqf-0583a436/" fontIcon="ti-linkedin" title="">
                        <Button aria-controls="simple-menu" aria-haspopup="true">
                            <span className={'fontIcon ' + 'ti-linkedin'}></span>
                            <span className="text">                                
                            </span>
                        </Button>
                    </a> </li>
                    {/* <li><LinkItem to="/courses" fontIcon="ti-twitter" title=""></LinkItem> </li> */}
                </ul>
            </React.Fragment>
        );
    }
}
