import React from 'react'
import dailyTasks from '../images/daily-tasks.png'
import iconMeeting from '../images/meeting.png'
import iconHomeOffice from '../images/home-office.png'
import GuidanceIcon from '../images/guidance.png'
import meetingIcon from '../images/meeting-1.png'
import podiumIcon from '../images/podium.png'
import interviewIcon from '../images/interview.png'
import { AnimatedOnScroll } from "react-animated-css-onscroll";

export const WhyUsText = [
    {
        "text": "Daily Mock interview",
        "icon": dailyTasks
    },
    { "text": "Weekly Mock interview", "icon": iconMeeting },
    // { "text": "Free Study Room", "icon": iconHomeOffice },
    { "text": "Career Guidance", "icon": GuidanceIcon },
    { "text": "Senior Students Guidance", "icon": meetingIcon },
    { "text": "Course Completion Certificate", "icon": podiumIcon },
    { "text": "Weekly Assignment", "icon": interviewIcon }
]

const WhyUs = () => {

    return (
        <>
            <div id='whyUsSection'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12'>
                            <h1>Why to join Velocity?</h1>
                        </div>
                        {WhyUsText?.map((item, index) => {
                            return (
                                <AnimatedOnScroll className='col-sm-6 col-md-4 mb-4' animationInDelay={index * 200} animationIn="fadeInUp" isVisible={true}> {item.name}
                                    <div className='inner'>
                                        <p className='circle'>
                                            <img src={item?.icon} title="" alt='' />
                                        </p>
                                        {item?.text}
                                    </div>
                                </AnimatedOnScroll>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyUs