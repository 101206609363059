/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import AboutImage from '../../images/aboutUs.jpg';
import axios from 'axios';
import FreeResources from '../../components/website/freeResources';
import Placeholder from '../../images/placeholder.jpg'
import { Button } from '@material-ui/core';
import { Loader } from '../../components/website/Loader';
import MissionIcon from '../../images/mission.png'
import VissionIcon from '../../images/vision.png'
import dailyTasks from '../../images/daily-tasks.png'
import iconMeeting from '../../images/meeting.png'
import iconHomeOffice from '../../images/home-office.png'
import GuidanceIcon from '../../images/guidance.png'
import meetingIcon from '../../images/meeting-1.png'
import podiumIcon from '../../images/podium.png'
import interviewIcon from '../../images/interview.png'
import WhyUs from '../../components/WhyUs';

// export const WhyUs = [
//     {
//         "text": "Daily Mock interview",
//         "icon": dailyTasks
//     },
//     { "text": "Weekly Mock interview", "icon": iconMeeting },
//     { "text": "Free Study Room", "icon": iconHomeOffice },
//     { "text": "Career Guidance", "icon": GuidanceIcon },
//     { "text": "Senior Students Guidance", "icon": meetingIcon },
//     { "text": "Students Felicitations", "icon": podiumIcon },
//     { "text": "weekly Assignment", "icon": interviewIcon }
// ]

export default class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null,
            tableData: null
        };

    }

    async componentDidMount() {
        this.getAllPages();
        this.getAllCMS2('Instructor');
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)
                const meet_trainer = res.data.data.filter(item => item._id == "5f7746278e396339ca9b03d2");
                console.log("meet_trainer", meet_trainer[0])
                this.setState({ pages: meet_trainer[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }





    async getAllCMS2(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Testimonials res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="About VCTC Pune "></SubHeader>
                <div className="section aboutus">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-sm-12 text-left">
                                {this.state.pages === null ? <Loader></Loader> : <div dangerouslySetInnerHTML={{ __html: this.state.pages?.description }}></div>}
                            </div>
                        </div>
                    </div>
                </div>


                <div id='AboutSection'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12'>
                                <h1>About Us</h1>
                                <p>Velocity Corporate Training Center is not a class or institute it's a family who cares about every family members, we conduct daily telephonic Mock interview which interns helps students in the telephonic round at the time of interview. Along with these Velocity Alumni who placed in MNC's and have experience more than 3 years conduct Mock interviews on weekends. We maintain every individual's record and at the last based on that we prepare students best quality resumes. Writing resume is a skill to promote yourself in current challenging market. Skilful and value added resume will be prepared and uploaded by experienced and skilled trainers of Velocity.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='AboutSection'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-sm-12 col-md-6'>
                                <div className='inner'>
                                    <img src={MissionIcon} alt='' title='' />
                                    <h1>Our Mission</h1>
                                    <p>To build an ideal institute that radiates Peace and harmony by imparting pure education to all the strata of society. Only professionals can make you professional,Come to us learn to Earn.</p>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-6'>
                                <div className='inner'>
                                    <img src={VissionIcon} alt='' title='' />
                                    <h1>Our Vision</h1>
                                    <p>To aid in the overall growth and character development of the students by focusing on quality education that is pure and wholesome.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <WhyUs></WhyUs>
              

            </React.Fragment>
        );
    }
}
