/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from "../LinkItem";
import dashboardIcon from '../../images/dashboardIcon.png'
import myEnrolledCoursesIcon from '../../images/myEnrolledCoursesIcon.png'
import freeDownloadIcon from '../../images/freeDownloadIcon.png'
import settingIcon from '../../images/settingIcon.png'

export default class StudentHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="student-header">
                    <div className="container">
                        <div className="header-menu-admin">
                            <ul>
                                {PredefinedMenuUserSubMenu.map(item => {
                                    return (
                                        <li key={item.name}>
                                            <LinkItem to={item.linkto} icon={item.icon} title={item.name}></LinkItem>
                                        </li>
                                    )
                                })
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export const PredefinedMenuUserSubMenu = [

    {
        "id": 1,
        "name": "Dashboard",
        "linkto": "/students/dashboard",
        "icon": dashboardIcon
    },

    {
        "id": 2,
        "name": "My Enrolled Courses",
        "linkto": "/students/my-enrolled-courses",
        "icon": myEnrolledCoursesIcon
    },

    {
        "id": 3,
        "name": "Free Resources",
        "linkto": "/students/free-resouces",
        "icon": freeDownloadIcon
    },
    {
        "id": 4,
        "name": "Your Order History",
        "linkto": "/students/order-history",
        "icon": settingIcon
    },
    // {
    //     "id": 5,
    //     "name": "Payment History",
    //     "linkto": "/students/payment-history"
    // }
]