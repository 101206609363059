/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import { Button } from "@material-ui/core";
import { NavLink } from 'react-router-dom';

export default class CourseCFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfa_menu: this.props.cfa_menu,
            hamburgerMenu: false,
            drodpdownState: false,
            mobileOnly: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
        this.resize();
    }

    resize() {
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    handleClose() {
        this.setState({ hamburgerMenu: false })

    }
    handleOpen() {
        this.setState({ hamburgerMenu: true })
    }

    handleOpen3() {
        this.props.handleOpen2(true)
    }

    render() {
        return (
            <React.Fragment>
                <li>
                    {this.state.hamburgerMenu === true ? ''
                        :
                        <ul>
                            {this.state.cfa_menu !== null ? this.state.cfa_menu.map((item) => {
                                return (
                                    <React.Fragment>
                                        <li><NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen3() } : ''} to={`/courses/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"} > {item.name} </NavLink></li>
                                    </React.Fragment>
                                );
                            })
                                : ''
                            }
                        </ul>
                    }
                </li>

            </React.Fragment>
        );
    }
}
