/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import CourseImage from '../../images/course_image.jpg';
import FreeResources from '../../components/website/freeResources';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import ReactModal from 'react-modal';
import InquireCourse from '../../components/website/inquiryCourse';
import { withSnackbar } from 'notistack';
import BuyThiCourse from './payment';
import Tooltip from '@material-ui/core/Tooltip';
import Blog from '../../components/website/blog';
import iconDownload from '../../images/iconDownload.png'
import iconEnquiry from '../../images/iconEnquiry.png'
import buyNowIcon from '../../images/buyNowIcon.png'
import { Loader } from '../../components/website/Loader';
import { NavLink } from 'react-router-dom';
import { AnimatedOnScroll } from "react-animated-css-onscroll";

import courseImage from '../../images/courseImage.png';

import powerBI from '../../images/power-bi.png';
import datascience from '../../images/data-science.png';
import javadevelopment from '../../images/java-development.png';
import awsdevops from '../../images/aws-devops.png';
import rpa from '../../images/robotics-process-automation-development-rpa.png';
import elttesting from '../../images/elt-testing.png';
import angulardevelopment from '../../images/angular-development.png';
import bigdata from '../../images/hadoop-big-data-data-engineer.png';
import saleforceMain from '../../images/saleforceMain.png';
import softwaretesting from '../../images/software-testing.png';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

function courseFess(params, params2) {
    console.log("params", params?.length)
    console.log("params2", params2)
    if (params?.length === 0) { return 0 }
    const baseFees = params;
    let discount = null
    if (params2 == null) {
        discount = 0
    } else {
        discount = params2
    }

    console.log("baseFees", baseFees)
    console.log("discount", discount)
    const discountAmount = (parseInt(baseFees) / 100) * parseInt(discount)
    const total = parseInt(baseFees) - parseInt(discountAmount)
    console.log("total", total)
    return total
}

class CourseDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            tableData: null,
            centers: null,
            attachment: null,
            dataNew: 0,
            discountSelect: null,
            tableModuleData: null,
            discount_table: [],
            showContent: '',
            user_id: localStorage.getItem('user_id') || null,
            fields: {
                batch_name: '',
                plan_name: '',
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.DownloadCurriculum = this.DownloadCurriculum.bind(this);
        this.handleChangeDiscount = this.handleChangeDiscount.bind(this);
        this.showCard = this.showCard.bind(this);



    }

    handleOpenModal(data) {

        console.log("this.state.fields.plan" + this.state.fields.plan_name)
        console.log("this.state.fields.batch_name" + this.state.fields.batch_name)

        if (this.state.fields.batch_name?.length !== 0) {

            this.setState({ showModal: true });
        } else {

            const message = "Please select the Batch & Plan";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 7000,
            });
        }

    }

    handleChangeDiscount(e) {
        const discountSelect = e.target.value;
        console.log('fields', discountSelect);
        this.setState({ discountSelect: discountSelect })
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    DownloadCurriculum() {
        console.log("Data Download Curriculum")

        if (this.state.user_id == null) {

            this.props.history.push({
                pathname: '/login/'
            });

        } else {
            window.open(process.env.react_app_base_url + "/" + this.state.tableData.attachment, '_blank');
        }
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }

    async componentDidMount() {
        console.log("this.props.match.params.id", this.props.match.params.id)
        console.log("this.props.match.params.title", this.props.match.params.title)
        const id = this.props.match.params.id
        this.getAllCMS(id);
        this.getAllBatch(id);
        this.getAllModuleByCourse(id)
        // this.getAllDiscount(id)
        const fields = {
            batch_name: '',
            plan_name: '',
        }

        this.setState({ fields: fields })

    }

    async getAllModuleByCourse(module_id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/modules/moduleByCourse/' + module_id + '?status=0' +
            this.state.currentCourseBatchId)
            .then(res => {
                console.log("Resources moduleByCourse", res)
                this.setState({ tableModuleData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    componentWillReceiveProps(nextProps) {

        const fields = {
            batch_name: '',
            plan_name: '',
        }

        this.setState({ fields: fields })

        console.log("nextProps", nextProps.match.params.title)
        console.log("nextProps", nextProps.match.params.id)
        const id = nextProps.match.params.id
        this.getAllCMS(id);
        this.getAllBatch(id);
        this.getAllModuleByCourse(id)
    }


    async getAllCMS(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources/' + id)
            .then(res => {
                console.log("Courses res", res)
                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getAllBatch(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/centerbatch/byCourseId/' + id + "?status=0")
            .then(res => {
                console.log("Courses res", res)
                const dataNew = res.data.data;
                console.log("dataNew", dataNew.length)
                this.setState({ centers: res.data.data.reverse(), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }


    async getAllDiscount(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/discount/byProduct/' + id)
            .then(res => {
                console.log("Courses res", res)
                this.setState({ discount_table: res.data.data, loading: false, discountSelect: res.data.data[0].discount })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    showCard(data) {
        console.log("adasd")
        this.setState({ showContent: data });
    }


    render() {
        return (
            <React.Fragment>
                {this.state.tableData == null ? <Loader></Loader> :
                    <React.Fragment>
                        <SubHeader  image={this.state.tableData.image !== "null" ? process.env.react_app_base_url + "/" + this.state.tableData.image : undefined} title={this.state.tableData?.title}></SubHeader>
                        <div className="courseDetails">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 col-sm-12 text-left elementGab">
                                        <h3>{this.state.tableData.name}</h3>

                                        {this.state.tableData.short_description &&
                                            <div className="desc">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData.short_description }}></div>
                                            </div>
                                        }
                                        {this.state.tableData.description &&
                                            <div className="description">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData.description }}></div>
                                            </div>
                                        }

                                        <div className='col-md-12 mb-4' style={{
                                            backgroundImage: `url("${this.state.tableData?.name === "Software Testing" ? softwaretesting :
                                                this.state.tableData?.name === "Java Development" ? javadevelopment :
                                                    this.state.tableData?.name === "Power-BI" ? powerBI :
                                                        this.state.tableData?.name === "AWS/DevOps" ? awsdevops :
                                                            this.state.tableData?.name === "Salesforce Admin+Development" ? saleforceMain :

                                                                this.state.tableData?.name === "Robotics Process Automation Development (RPA)" ? rpa :
                                                                    this.state.tableData?.name === "Angular Development" ? angulardevelopment :
                                                                        this.state.tableData?.name === "Data Science" ? datascience :
                                                                            this.state.tableData?.image === 'null' ? courseImage : courseImage}")`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: "center", backgroundRepeat: "no-repeat"
                                        }}>

                                            <div className='minHeight370'></div>
                                        </div>
                                        <div className='syllabusWrapper'>
                                            {this.state.tableModuleData === null ? '' :
                                                <>
                                                    <h3 className='title'>Syllabus</h3>
                                                    {this.state.tableModuleData.map(item => {
                                                        return (
                                                            <React.Fragment>
                                                                <div class="card">
                                                                    <div className={`card-header curser-pointer ${this.state.showContent === item?.name ? 'inactive' : 'active'}`} onClick={() => this.showCard(this.state.showContent === item?.name ? '' : item?.name)}>
                                                                        {item?.name} <span className='bigger float-right'>{this.state.showContent === item?.name ? '-' : '+'}</span>
                                                                    </div>
                                                                    {this.state.showContent && this.state.showContent === item?.name &&
                                                                        <div class="card-body">
                                                                            <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {this.state.tableData.fees !== "null" &&
                                        <div className="col-md-4 rightPanel col-sm-12 text-left elementGab">
                                            <div className='inner'>
                                                <h5 className='mb-0 pb-0'>Fees : {this.state.tableData.fees}  </h5>
                                                <p>Installment : {this.state.tableData.fees / 2} + {this.state.tableData.fees / 2}</p>
                                                <h5>Duration : {this.state.tableData.duration} </h5>
                                                <div className='courseBottom'>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.tableData?.right_section }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {this.state.tableData.why_this_course !== "undefined" &&
                                    <div className="WhyThisCourseWrapper">
                                        <div className="container">
                                            <div className='col-sm-12 col-md-12'>
                                                <div className="description">
                                                    <h2>Why {this.state.tableData.name}</h2>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.tableData.why_this_course }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row" id="WhyThisCourse">

                                    {this.state.tableData.where_it_will_used !== "undefined" &&
                                        <div className='col-sm-12 col-md-6'>
                                            <div className="description">
                                                <h2>What is {this.state.tableData.name} where it will be used?</h2>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData.where_it_will_used }}></div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.tableData.why_our_institute !== "undefined" &&
                                        <div className='col-sm-12 col-md-6'>
                                            <div className="description">
                                                <h2>Why our institute for learning {this.state.tableData.name} course?</h2>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData.why_our_institute }}></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {this.state.tableData.key_features !== "undefined" &&
                                    <div className="WhyThisCourseWrapper">
                                        <div className='col-sm-12 col-md-12'>
                                            <div className="description">
                                                <h2>Key features for your course {this.state.tableData.name}?</h2>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData.key_features }}></div>
                                            </div>
                                        </div>


                                    </div>
                                }
                                <div className='container'>
                                    <div className="compare">

                                        <h3>Upcoming Batches</h3>
                                        <table className='table table-bordered'>

                                            <thead>
                                                <tr>
                                                    <th style={{ width: '150px' }}>Course Name</th>
                                                    <th style={{ width: '100px' }}>Duration</th>
                                                    <th style={{ width: '100px' }}>Date</th>
                                                    <th style={{ width: '60px' }}>Slot </th>
                                                    <th style={{ width: '70px' }}>Payment</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.centers !== null ?
                                                    <React.Fragment>
                                                        {this.state.centers?.map((item, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td style={{ fontSize: '14px', lineHeight: '18px' }}>
                                                                            <AnimatedOnScroll animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}> {item.name}  {item.start_date !== 'TBD' ? <span className='newTag'>New</span> : ''} </AnimatedOnScroll> </td>
                                                                        <td> <AnimatedOnScroll animationInDelay={index * 120} animationIn="fadeInUp" isVisible={true}>{item?.duration}</AnimatedOnScroll> </td>
                                                                        <td> <AnimatedOnScroll animationInDelay={index * 120} animationIn="fadeInUp" isVisible={true}>{item.start_date}</AnimatedOnScroll> </td>
                                                                        <td> <AnimatedOnScroll animationInDelay={index * 160} animationIn="fadeInUp" isVisible={true}>{item.batch_days}</AnimatedOnScroll> </td>
                                                                        <td>  <AnimatedOnScroll animationInDelay={index * 140} animationIn="fadeInUp" isVisible={true}>
                                                                            {item.end_date === 'https://www.google.com' ?
                                                                                <button className='btn btn-primary btn-sm' disabled={item.end_date === 'https://www.google.com'}>
                                                                                    Pay Now</button> : <a className='btn btn-primary btn-sm' aria-disabled={true} rel="noopener noreferrer" target='_blank' href={item.end_date}>Pay Now</a>}

                                                                        </AnimatedOnScroll> </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <tr>
                                                            <td colSpan="5"> No Batch Available </td>
                                                        </tr>
                                                    </React.Fragment>
                                                }
                                            </tbody>
                                        </table>



                                    </div>
                                </div>
                                {/* {this.state.tableData.fees !== "null" && this.state.tableData.fees.length !== 0 ?
                                            <React.Fragment>
                                                <div className="compare">
                                                    <h5><strong>Plan Comparison</strong></h5>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Video & Notes</th>
                                                                <th>Video</th>
                                                            </tr>
                                                        </thead>
                                                        <tr>
                                                            <td>Video</td>
                                                            <td><span className="ti-check-box"></span></td>
                                                            <td><span className="ti-check-box"></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Notes</td>
                                                            <td><span className="ti-check-box"></span></td>
                                                            <td> - </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </React.Fragment>
                                            : null} */}

                                {this.state.discount_table !== "null" && this.state.discount_table.length !== 0 ?
                                    <React.Fragment>
                                        <div className="compare">
                                            <h5><strong>Discount</strong></h5>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Discount</th>
                                                    </tr>
                                                </thead>

                                                {this.state.discount_table.length !== 0 ?
                                                    <React.Fragment>
                                                        {this.state.discount_table.map((item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td> {item.title} </td>
                                                                        <td> {item.description} </td>
                                                                        <td> {item.start_date} </td>
                                                                        <td> {item.end_date} </td>
                                                                        <td> {item.discount} </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <tr>
                                                            <td colSpan="5"> No Discount Available </td>
                                                        </tr>
                                                    </React.Fragment>
                                                }
                                            </table>
                                        </div>
                                    </React.Fragment>
                                    : null}
                                <div className='terms_conditions'>
                                    <h5>Please read the Terms and Conditions (T&Cs) carefully prior to enroll the course</h5>
                                    <ul>
                                        <li>The fees will be non-refundable and non-transferable in any circumstances.</li>
                                        <li>Batch or Course change is strictly not allowed.</li>
                                        <li>Velocity provides corporate level training.</li>
                                        <li>Training will be provided according to the specified curriculum.</li>
                                        <li>Candidates will get interview calls from different job portals.</li>

                                    </ul>
                                    <p>All rights reserved to Velocity Corporate Training Center*</p>
                                    <p>If you have any queries/ doubt regarding payment, please contact us at support@vctcpune.com</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>

        );
    }
}


export default withSnackbar(CourseDetails);