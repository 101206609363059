/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import LinkItem from "../../components/LinkItem";

class ServerDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    componentDidMount() {
        
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Down for Maintenance"></SubHeader>
                <div className="thanks-message">
                <h2>Sorry, we're down for maintenance</h2>
                <p>Website is temporarily unavailable due to planned maintenance.</p>
                <p>Have a great day!</p>
                <LinkItem className="order-free-resouce" to={'/'}  title={'Back to Site'}></LinkItem>
                </div>




            </React.Fragment>
        );
    }
}

export default withSnackbar(ServerDown);
