/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import AboutImage from '../../images/aboutUs.jpg';
import axios from 'axios';
import FreeResources from '../../components/website/freeResources';
import Placeholder from '../../images/placeholder.jpg'
import { Button } from '@material-ui/core';
import { Loader } from '../../components/website/Loader';
import MissionIcon from '../../images/mission.png'
import VissionIcon from '../../images/vision.png'
import dailyTasks from '../../images/daily-tasks.png'
import iconMeeting from '../../images/meeting.png'
import iconHomeOffice from '../../images/home-office.png'
import GuidanceIcon from '../../images/guidance.png'
import meetingIcon from '../../images/meeting-1.png'
import podiumIcon from '../../images/podium.png'
import interviewIcon from '../../images/interview.png'
import WhyUs from '../../components/WhyUs';
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import Marquee from 'react-fast-marquee';
import Slider from "react-slick";



export default class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null,
            tableData: null
        };

    }

    async componentDidMount() {
        this.getAllPages();
        this.getAllCMS2('Instructor');
        this.getAllBatch()
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)
                const meet_trainer = res.data.data.filter(item => item._id == "5f7746278e396339ca9b03d2");
                console.log("meet_trainer", meet_trainer[0])
                this.setState({ pages: meet_trainer[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }





    async getAllCMS2(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Testimonials res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    async getAllBatch() {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/centerbatch?status=0')
            .then(res => {
                console.log("Courses res", res)
                const dataNew = res.data.data;
                console.log("dataNew", dataNew.length)
                this.setState({ centers: res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Upcoming Batches"></SubHeader>
                <div className='eventsUpcomingBatchWrapper' id='upcoming_batches'>
                    <div className='wrapper'>
                        <div className='row'>
                            <div className='col-md-12 mb-5'>
                                <div className='eventWrapper'>
                                    <h3>Upcoming Batches</h3>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '150px' }}>Course Name</th>
                                                <th style={{ width: '100px' }}>Duration</th>
                                                <th style={{ width: '100px' }}>Date</th>
                                                <th style={{ width: '60px' }}>Slot </th>
                                                <th style={{ width: '70px' }}>Payment</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.centers !== null ?
                                                <React.Fragment>
                                                    {this.state.centers?.map((item, index) => {
                                                        return (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px', lineHeight: '18px' }}>
                                                                        <AnimatedOnScroll animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}> {item.name}  {item.start_date !== 'TBD' ? <span className='newTag'>New</span> : ''} </AnimatedOnScroll> </td>
                                                                    <td> <AnimatedOnScroll animationInDelay={index * 120} animationIn="fadeInUp" isVisible={true}>{item?.duration}</AnimatedOnScroll> </td>
                                                                    <td> <AnimatedOnScroll animationInDelay={index * 120} animationIn="fadeInUp" isVisible={true}>{item.start_date}</AnimatedOnScroll> </td>
                                                                    <td> <AnimatedOnScroll animationInDelay={index * 160} animationIn="fadeInUp" isVisible={true}>{item.batch_days}</AnimatedOnScroll> </td>
                                                                    <td>  <AnimatedOnScroll animationInDelay={index * 140} animationIn="fadeInUp" isVisible={true}>
                                                                        {item.end_date === 'https://www.google.com' ?
                                                                            <button className='btn btn-outline-primary btn-sm' disabled={item.end_date === 'https://www.google.com'}>
                                                                                Pay Now</button> : <a aria-disabled={true} className='btn btn-primary btn-sm' rel="noopener noreferrer" target='_blank' href={item.end_date}>Pay Now</a>}

                                                                    </AnimatedOnScroll> </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan="5"> No Batch Available </td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                        </tbody>
                                    </table>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper'>
                    <div className='row'>
                        <div className='col-md-12 mb-5'>
                            <div className='terms_conditions'>
                                <h5>Please read the Terms and Conditions (T&Cs) carefully prior to enroll the course</h5>
                                <ul>
                                    <li>The fees will be non-refundable and non-transferable in any circumstances.</li>
                                    <li>Batch or Course change is strictly not allowed.</li>
                                    <li>Velocity provides corporate level training.</li>
                                    <li>Training will be provided according to the specified curriculum.</li>
                                    <li>Candidates will get interview calls from different job portals.</li>

                                </ul>
                                <p>All rights reserved to Velocity Corporate Training Center*</p>
                                <p>If you have any queries/ doubt regarding payment, please contact us at support@vctcpune.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <WhyUs></WhyUs>





            </React.Fragment>
        );
    }
}
