/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubHeader from '../../components/website/subHeader';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';
import { Loader } from '../../components/website/Loader';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


export default class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            tableData: null
        };
    }

    handleChange = (event, newValue) => {

        console.log("asdasd", newValue)
        this.setState({ value: newValue })

    }

    async componentDidMount() {

        this.getAllCMS('FAQs');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Blog res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Frequently Asked Questions"></SubHeader>
                <div className='wrapperContent'>
                    <div className="faqs">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12 col-sd-12">
                                    <Paper square>
                                        <TabPanel value={this.state.value} index={0} >
                                            {this.state.tableData === null ?  <Loader></Loader> : this.state.tableData.map((item, index) => {
                                                return (
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography className={''}> {index + 1}. {item.cms_title} {item.cms_category == "null" ? '' : item.cms_category} </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                <div dangerouslySetInnerHTML={{ __html: item.cms_description }}></div>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })
                                            }
                                        </TabPanel>
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
