/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import ReactModal from 'react-modal';
import LoginInner from '../../components/website/loginInner';
import SignupInner from '../../components/website/signupInner';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}


function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}



const __DEV__ = document.domain === 'localhost';

class Payment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "testing",
			showModal: false,
			value: 0,
			organization_id: '5f048db166e1c93c780e7e7c',
			user_id: localStorage.getItem('user_id') || null,
			user_email: localStorage.getItem('user_email') || null
		};

		this.displayRazorpay = this.displayRazorpay.bind(this);
		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleLanguage = this.handleLanguage.bind(this);
		this.handleLanguageonSignUp = this.handleLanguageonSignUp.bind(this);

	};


	handleOpenModal(data) {
		console.log("data" + data)
		this.setState({ showModal: true });
	}

	handleCloseModal() {
		this.setState({ showModal: false });
	}

	handleChangeIndex = (event, value) => {
		this.setState({ value });
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	componentDidMount() {

		// alert("this.state.user_id this.state.user_id this.state.user_id "+ this.state.user_id)

	}

	handleLanguage(data) {
		this.setState({ user_id: data, showModal: false });
		this.displayRazorpay();
	}


	handleLanguageonSignUp(data) {
		this.setState({ value: 0 });
	}

	componentWillReceiveProps() {
		console.log("user_id", this.state.user_id)
	}


	async displayRazorpay() {

		// alert(this.state.user_id)

		if (this.state.user_id == null) {
			this.setState({ showModal: true })
			return
		}

		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

		let inputdata = {
			course_id: this.props.course_id,
			course_name: this.props.course_name,
			batch_id: this.props.batch_id,
			batch_name: this.props.batch_name,
			plan_name: this.props.plan_name,
			// discount_percentage: this.props.discount_percentage,
			// amount: "412.00",
			user_id: localStorage.getItem('user_id'),
			email_id: localStorage.getItem('user_email'),
			created_by: localStorage.getItem('user_name'),
			organization_id: this.state.organization_id,
			status: "1",
		}

		try {
			const res = await axios.post(process.env.react_app_base_url + '/api/v1/order/placeorder', inputdata);
			const resp = res.data;
			if (res.status === 200) {
				console.log('placeorder', resp);

				const options = {
					key: __DEV__ ? 'rzp_live_fqkkZuLz2e3L4L' : 'rzp_live_fqkkZuLz2e3L4L',
					currency: resp.currency,
					amount: parseInt(resp.amount),
					order_id: resp.order_id,
					name: resp.course_name,
					description: resp.batch_name + " - " + resp.plan_name,
					image: process.env.react_app_base_url_app + '/logo.jpg',
					handler: async function (response) {
						console.log("response response response response response response response ", response)
						const paymentDetails = {
							"payment_id": response.razorpay_payment_id,
							"order_id": response.razorpay_order_id,
							"razorpay_signature": response.razorpay_signature,
						}
						const patchData = {
							"id": resp.id,
							"payment_id": response.razorpay_payment_id,
							"order_id": response.razorpay_order_id,
							"razorpay_signature": response.razorpay_signature
						}

						try {
							const respPatch = await axios.patch(process.env.react_app_base_url + '/api/v1/order/placeorder', patchData);
							if (respPatch.status === 200) {
								alert("We have received payment. Your order in progress, you can check the status in My Account - Payment details section.");
								// window.location.assign(process.env.react_app_base_url_app+"/students/dashboard/")
								this.props.history.push(process.env.react_app_base_url_app+"/students/thank-you-purchase/")
							}
						} catch (error) {
							alert('something went wrong, please try again');
							
						}

					},
					prefill: {
						name: localStorage.getItem('user_name'),
						email: localStorage.getItem('user_email'),
						contact: localStorage.getItem('user_mobile'),
					}
				}
				const paymentObject = new window.Razorpay(options)
				paymentObject.open()


			} else {
				alert('Failed');

				
			}

		} catch (error) {
			alert('Failed');
			console.log('placeorder API error', error.response.data.messages);

			const message = error.response.data.messages;
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 7000,
            });
		}

	}

	render() {
		const { value } = this.state;

		return (
			<React.Fragment>

				<ReactModal
					isOpen={this.state.showModal}
					contentLabel="Minimal Modal Example"
					className="Modal2"
					overlayClassName="Overlay"
				>
					<div className="popup-wrapper">
						<Button className="closeBtn" color="primary" variant="outlined" onClick={this.handleCloseModal}>
							<span className="ti-close iconSpan"></span> Close
                        </Button>
						<div className="popup2">
							<div className={''}>
								<AppBar position="static" color="default">
									<Tabs
										value={value}
										onChange={this.handleChange}
										indicatorColor="primary"
										textColor="primary"
										variant="fullWidth"
										aria-label="full width tabs example"
									>
										<Tab label="Login" {...a11yProps(0)} />
										<Tab label="Sign Up" {...a11yProps(1)} />
									</Tabs>
								</AppBar>

								<SwipeableViews
									index={value}
									onChangeIndex={this.handleChangeIndex}
								>
									<TabPanel value={value} index={0}>
										<LoginInner onSelectLanguage={this.handleLanguage}></LoginInner>
									</TabPanel>
									<TabPanel value={value} index={1}>
										<SignupInner onSignUp={this.handleLanguageonSignUp}></SignupInner>
									</TabPanel>

								</SwipeableViews>
							</div>

						</div>
					</div>
				</ReactModal>

				<Button variant="contained" disabled={this.props.batch_id.length == 0 ? true : false } onClick={this.displayRazorpay} className="bigBtn" color="primary"> 
				<img src={this.props.icon} alt="" title='' /> 
				Buy this course </Button>
				
			</React.Fragment>
		);
	}
}

export default withRouter (withSnackbar(Payment));