/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import DefaultImage from '../../images/default2.jpg';

export default class CoursesComp extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="row text-left">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <h3>{this.props.title}</h3>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="image">
                            {this.props.image == null ? <img src={DefaultImage} alt="" /> : <img src={process.env.react_app_base_url + "/" + this.props.image} alt="" />}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="text-wrapper">
                            <div className="short_desc" id="hide_p">
                                <div dangerouslySetInnerHTML={{ __html: this.props.short_description }}></div>
                            </div>
                            <LinkItem className="course_btn" to={this.props.readmore} fontIcon={"ti-arrow-right"} title="View Course Details"></LinkItem>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
