import React, { Component, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Banner from '../../images/BannerNew.png'
import axios from 'axios';
import Slider from "react-slick";
import { Loader } from './Loader';
import user_placeholder from '../../images/DefaultCourse.jpeg';
import { Animated } from "react-animated-css";

import courseImage from '../../images/courseImage.png';

import BannerJava from '../../images/BannerJava.png';
import bannerDataScience from '../../images/bannerDataScience.png';
import BannerSoftwareTesting from '../../images/BannerSoftwareTesting.png';
import BannerAWS from '../../images/BannerAWS.png';
import BannerSalesForce from '../../images/BannerSalesForce.png';
import BannerOtherCourses from '../../images/BannerOtherCourses.png';
import BannerAboutUs from '../../images/BannerAboutUs.png';

import TermsConditions from '../../images/TermsConditions.png';
import PrivacyPolicy from '../../images/PrivacyPolicy.png';
import RefundPolicy from '../../images/RefundPolicy.png';





const HomeBanner = () => {
    const history = useHistory()
    const [data, setData] = useState({
        tableData: [],
        loading: false
    })
    console.log("history", history?.location?.pathname)

    useEffect(() => {
        getAllCMS2('Slider')
    }, [])


    const getAllCMS2 = (type) => {
        setData((prevState) => ({
            ...prevState,
            loading: true
        }));
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Courses res", res)
                setData((prevState) => ({
                    ...prevState,
                    tableData: res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)),
                    loading: false
                }));
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 4000
    };
    return (
        <React.Fragment>
            {history?.location?.pathname === '/' &&
                <div className='minHeight'>
                    <Slider {...settings}>
                        {data?.tableData === null ? <Loader></Loader> : data?.tableData.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).map((item, index) => {
                            console.log("item", item)
                            console.log("item image", item?.cms_link)
                            return (
                                <div className='sliderWrapper2'>
                                    <div className='itemPlacement' 
                                    
                                                >
                                                    <img src={item?.cms_link === "about-us" ? BannerAboutUs :
                                                        item?.cms_link === "data-science" ? bannerDataScience :
                                                        item?.cms_link === "software-testing" ? BannerSoftwareTesting :
                                                        item?.cms_link === "java-development" ? BannerJava :
                                                        item?.cms_link === "aws-devops" ? BannerAWS :
                                                        item?.cms_link === "salesforce" ? BannerSalesForce :

                                                        item?.cms_link === "return-refund-policy" ? RefundPolicy :
                                                        item?.cms_link === "privacy-policy" ? PrivacyPolicy :
                                                        item?.cms_link === "terms-conditions" ? TermsConditions :
                                                        item?.cms_link === "other-courses" ? BannerOtherCourses : BannerAboutUs} alt="" title="" />
                                                    
                                                     </div>
                                    {/* <div className='logoPlaceholder'>
                                        <Animated animationInDelay={100} animationIn="bounceInRight" animationOut="fadeOut" isVisible={true}>
                                            <h2>Velocity Pune</h2>
                                        </Animated>
                                        <Animated animationInDelay={200} animationIn="bounceInRight" animationOut="fadeOut" isVisible={true}>
                                            <h3>Empowering Education</h3>
                                        </Animated>
                                    </div> */}
                                    <div className='courseCategory2'>
                                        <div className='inner'>
                                            <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                <h3>{item.cms_title}</h3>
                                            </Animated>
                                            <div className='short_description'>
                                                <Animated animationInDelay={200} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                     
                                                    <div dangerouslySetInnerHTML={{ __html: item.cms_description }}></div>
                                                </Animated>
                                            </div>
                                            <Animated animationInDelay={300} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                <NavLink to={item?.cms_tags}>View Details</NavLink>
                                            </Animated>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            }

        </React.Fragment>
    );
}

export default HomeBanner

