/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import axios from "axios";
import Logo from "../../images/logo.jpg";
import SignupInner from '../../components/website/signupInner';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log("fields", this.state.fields);
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Sign Up"></SubHeader>
                <SignupInner redirect="/login/"></SignupInner>                
            </React.Fragment>
        );
    }
}

export default withSnackbar(SignUp);
