import React from 'react';
import Header from '../components/website/header';
import Footer from '../components/website/footer';
import MeetTrainer from '../components/website/meetTrainer';
import { Route, Redirect } from 'react-router-dom';

export default function LayoutStudentRoute({ component: Component, ...rest }) {
  const user_user_type = localStorage.getItem('user_user_type');
  const user_token = localStorage.getItem('user_token');
  return (
    <Route {...rest} render={(props) => (
      user_user_type !== null && user_user_type === 'Student' && user_token !== null ?
        <Layout>
          {Component &&
            <Component {...props} />
          }
        </Layout>
        :
        <React.Fragment>
          <Redirect to={{
          pathname: '/login/',
          state: { from: props.location, reason: 'access denied you dont have authorization to view this page' }
        }}
          />
        </React.Fragment>

    )} />
  );
}

function Layout({ children }) {
  return (
    <React.Fragment>
      <Header></Header>
      {children}
      
      <Footer></Footer>
    </React.Fragment>
  );
}
