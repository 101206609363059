import React from 'react';
import Header from '../components/website/header';
import { Route } from 'react-router-dom';
import Footer from '../components/website/footer';
import MeetTrainer from '../components/website/meetTrainer';

export default function LayoutRoute({ component: Component, ...rest }) {


  return (
    <Route {...rest} render={(props) => (
      <Layout>
        {Component &&
          <Component {...props} />
        }
      </Layout>
    )} />
  );
}

function Layout({ children }) {
  return (
    <React.Fragment>
      <div className='wrapperOuter'>
      <Header></Header>
      {children}
      {/* <MeetTrainer></MeetTrainer> */}
      <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
