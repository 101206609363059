/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import logo from '../../images/logo.png';
import { NavLink } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { withRouter } from "react-router";
import TopCourseMenuItem from './TopCourseMenuItem';
import AboutHome from '../../components/website/aboutHome';
import Popup from './Popup';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLogged: localStorage.getItem("user_user_type") || null,
            user_token: localStorage.getItem("user_token") || null,
            user_name: localStorage.getItem("user_name") || null,
            hamburgerMenu: false,
            drodpdownState: false,
            mobileOnly: false,
        };
    }


    componentDidMount() {
        console.log("this.state.user_user_type", this.state.user_user_type)
        console.log("this.state.user_token", this.state.user_token)
        console.log("this.state.user_name", this.state.user_name)
        window.addEventListener("resize", this.resize.bind(this));
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
        this.resize();
    }

    handleClose() {
        this.setState({ hamburgerMenu: false })

    }
    handleOpen() {
        this.setState({ hamburgerMenu: true })
    }

    handleOpen2() {
        console.log("Adasda")
        this.setState({ hamburgerMenu: true })
    }

    resize() {
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }


    logout = () => {
        // logout

        const formData = {
            'token': this.state.user_token
        }

        axios.post(process.env.react_app_base_url + '/api/v1/user/logout', formData, {
            headers: {
                "auth-token": `${this.state.user_token}`,
            }
        })
            .then(res => {
                console.log("res", res)
                const message = res.data.messages;

                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 7000,
                });

                localStorage.removeItem("user_id");
                localStorage.removeItem("user_user_type");
                localStorage.removeItem("user_mobile");
                localStorage.removeItem("user_name");
                localStorage.removeItem("user_email");
                localStorage.removeItem("user_token");

                this.props.history.push("/login/")
                this.setState({ userLogged: null, user_token: null })

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });
    }

    render() {

        const isCourses = this.props.location?.pathname?.includes('view-course-details')

        console.log("this.state.hamburgerMenu", this.state.hamburgerMenu)
        return (
            <React.Fragment>
                <div className='header'>
                    <div className='wrapper'>
                        <div className='logoWrapper'>
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 text-left flex-row">
                                    <div className='siteLogo'>
                                        <NavLink to="/" className="site-logo"> <img alt="Logo" src={logo} /></NavLink>
                                    </div>
                                    <div className='mainSiteMenu'>
                                        {this.state.hamburgerMenu === false ?
                                            <div className="hangerMenuClose">
                                                <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleOpen() }} >
                                                    <div className="material-symbols-outlined">
                                                        close
                                                    </div>
                                                </Button>
                                            </div>
                                            :
                                            <div className="hangerMenuOpen">
                                                <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleClose() }} >
                                                    <div className="material-symbols-outlined">
                                                        menu
                                                    </div>
                                                </Button>
                                            </div>
                                        }
                                        <div className='topLine'>
                                            <div className='tagLine'>
                                                <p className='bigtitle'>Velocity Corporate Training Center, Pune</p>
                                                <p>Our AIM is 1 Job = 1 FAMILY = 5 PERSON</p>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>

                                        {this.state.hamburgerMenu === true ? ''
                                            :
                                            <>
                                                <div className='overLayer' onClick={() => this.handleOpen()} ></div>
                                                <div className="top-menu">
                                                    <div className='mainMenu'>
                                                        <ul className=''>
                                                            {this.state.hamburgerMenu === false &&
                                                                <div className='hangerMenuOuter'>
                                                                    <div className="close-container" onClick={() => { this.handleOpen() }}>
                                                                        <div className="material-symbols-outlined">
                                                                            close
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <li> <NavLink
                                                                exact={true}
                                                                onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/" > Home</NavLink> </li>
                                                            <li><NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/about-us" > About Us</NavLink></li>
                                                            <TopCourseMenuItem
                                                                activeClassName={isCourses ? 'active' : 'inactive'}
                                                                handleOpen2={() => this.handleOpen2()} ></TopCourseMenuItem>
                                                            <li><NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/upcoming-batches"> Upcoming Batches</NavLink></li>
                                                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/testimonials" > Testimonials</NavLink> </li>
                                                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/cms/refer-earn/64436c340bd0df521f57f183" > Refer & Earn</NavLink> </li>
                                                            <li><NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/contact-us" title="Contact Us">Contact Us</NavLink> </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <AboutHome></AboutHome>
                <Popup></Popup>
            </React.Fragment>
        );
    }
}


export default withRouter(withSnackbar(Header));