/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import StudentHeader from '../../components/website/studentHeader';
import { withSnackbar } from "notistack";

import LinkItem from "../../components/LinkItem";


class ThankYouStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <React.Fragment>
                <StudentHeader></StudentHeader>
                <div className="thanks-message marginBottom">
                    <h2>Thank you for your purchase</h2>
                    <p>Thank you for your purchase. Please let us know if we can do anything else to help!</p>
                    <p>Have a great day!</p>
                    <LinkItem className="order-free-resouce" to={'/students/free-resouces'} title={'View Free Resouces'}></LinkItem>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(ThankYouStudent);
