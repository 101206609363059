/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { withSnackbar } from 'notistack';
import BlogItem from '../../components/website/elementItem';
import StudentHeader from '../../components/website/studentHeader';
import LinkItem from '../../components/LinkItem';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubHeader from '../../components/website/subHeader';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';

import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/website/Loader";
// import SwipeableViews from 'react-swipeable-views';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


class ViewStudentChapter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            files: [],
            fields: {},
            cms_description: null,
            cms_type: [],
            cms_category: [],
            type: null,
            cms_details: null,
            api_reps: false,
            action: null,
            tableData: null,
            tableModuleData: null,
            token: localStorage.getItem('admin_token'),
            organization_id: localStorage.getItem('organization_id'),
            admin_name: localStorage.getItem('admin_name'),
            module_title: null,
            module_id: null,
            currentCourseBatchId:
                localStorage.getItem("currentCourseBatchId") || null,

        };
    }

    async componentDidMount() {

        const title = this.props.match.params.title;
        const id = this.props.match.params.id;
        const module_title = this.props.match.params.module_title;
        const module_id = this.props.match.params.module_id;
        console.log("id, title, module_title, module_id", id, title, module_title, module_id)
        this.getAllCMS(id);
        this.getAllModuleByCourse(module_id)
        this.setState({ module_title: module_title, module_id: module_id })
    }


    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps.match.params.id)
        const title = nextProps.match.params.title;
        const id = nextProps.match.params.id;
        const module_title = nextProps.match.params.module_title;
        const module_id = nextProps.match.params.module_id;
        console.log("id, title, module_title, module_id", id, title, module_title, module_id)
        this.getAllCMS(id);
        this.getAllModuleByCourse(module_id)
        this.setState({ module_title: module_title, module_id: module_id })
    }


    async getAllCMS(id) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/chapters/chapterBymodules/' + id + '?status=0&batch_id=' +
            this.state.currentCourseBatchId)
            .then(res => {
                console.log("Resources chapterBymodules", res)
                this.setState({ tableData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getAllModuleByCourse(module_id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/modules/moduleByCourse/' + module_id + '?status=0&batch_id=' +
            this.state.currentCourseBatchId)
            .then(res => {
                console.log("Resources moduleByCourse", res)
                this.setState({ tableModuleData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        const batch_id = localStorage.getItem('currentCourseBatchId')
        return (
            <React.Fragment>
                <StudentHeader></StudentHeader>
                <div className="container">
                    <p className="backtoBtn">
                        <LinkItem to={`/students/view-modules-by-course/` + this.state.module_title + "/" + this.state.module_id + "/" + batch_id} title={'Back to Module'}></LinkItem>
                    </p>

                </div>
                <div className="student-dashboard">
                    <div className="container">
                        <div className="row text-left">

                            <div className="col-md-3">
                                {/* tableData */}
                                <ul className="list">
                                    {this.state.tableModuleData === null ? '' : this.state.tableModuleData.map(item => {
                                        return (
                                            <React.Fragment>
                                                <li> <NavLink
                                                    to={`/students/view-chapter-by-module/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}/${item.cource_name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item.cource_id.replace(/\s+/g, '-')}` + "/"}>
                                                    {item.name}
                                                </NavLink> </li>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </ul>


                            </div>

                            <div className="col-md-9" id="mainVideo2">

                                {this.state.tableData === null ?  <Loader></Loader> : this.state.tableData.length == 0 ? 'Chapter yet not uploaded for this module.' : ' '}


                                <Paper square>
                                    <TabPanel value={this.state.value} index={0} >
                                        {this.state.tableData === null ? '' : this.state.tableData.map((item, index) => {
                                            return (
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={'text-underline'}> {index + 1}. {item.name} {item.cms_category == "null" ? '' : item.cms_category} </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                        <div className="clearfix"></div>
                                                        <p className="float-left">
                                                            <LinkItem className="view-details"
                                                                variant="primary"
                                                                to={`/students/view-video-by-chapter/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}/${item.module_name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item.module_id.replace(/\s+/g, '-')}` + "/"}
                                                                title={'View Details'}
                                                            ></LinkItem>
                                                        </p>

                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })
                                        }
                                    </TabPanel>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(ViewStudentChapter);
