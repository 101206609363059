/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import CoursesComp from '../../components/website/courses';
import placementCompany from '../../images/placementCompany.png'
import AboutHome from '../../components/website/aboutHome';
import Blog from '../../components/website/blog';
import FreeResources from '../../components/website/freeResources';
import Testimonials from '../../components/website/testimonials';
import Instructors from '../../components/website/OurInstructors';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import { Loader } from '../../components/website/Loader';
import user_placeholder from '../../images/logo2.jpg';
import PlacementComp from '../../components/website/Placement';
import { Animated } from "react-animated-css";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import Marquee from 'react-fast-marquee';
import Slider from "react-slick";
import WhyUs from '../../components/WhyUs';

export default class home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PlacementData: [],
            CenterData: []
        };
    }

    async componentDidMount() {
        this.getAllBatch();
        this.getAllCMS("Placement");
        this.getAllCenters("Centers")
    }

    async getAllBatch() {
        this.setState({ loading: true })
        Axios.get(process.env.react_app_base_url + '/api/v1/centerbatch?status=0')
            .then(res => {
                console.log("Courses res", res)
                const dataNew = res.data.data;
                console.log("dataNew", dataNew.length)
                this.setState({ centers: res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }



    async getAllCMS(type) {
        this.setState({ loading: true })
        Axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("getAllCMS res", res)
                const filteredDates = res.data.data.sort((a, b) => new Date(b.cms_publish_date) - new Date(a.cms_publish_date))
                console.log("filteredDates", filteredDates)
                this.setState({ PlacementData: filteredDates, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getAllCenters(type) {
        this.setState({ loading: true })
        Axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("getAllCMS res", res)
                const filteredDates = res.data.data.sort((a, b) => new Date(b.order_id) - new Date(a.order_id))
                console.log("filteredDates", filteredDates)
                this.setState({ CenterData: filteredDates, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            autoplay: true,
            autoplaySpeed: 3000
        };
        return (
            <React.Fragment>
                {/* <PlacementComp></PlacementComp> */}
                {/* <div className='mb-8'>
                    <Marquee speed="100"> Payment Link will activate on 2nd Jan 2023 at 11AM </Marquee>
                </div> */}
                <div className='mt-20'>
                    <div className='eventsUpcomingBatchWrapper' id='upcoming_batches'>
                        <div className='wrapper'>
                            <div className='row justify-content-center'>
                                <div className='col-md-10'>
                                    <div className='eventWrapper'>
                                        <h3>Upcoming Batches</h3>
                                        <div className='customHeight'>
                                            <table className='table table-bordered'>

                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '150px' }}>Course Name</th>
                                                        <th style={{ width: '100px' }}>Duration</th>
                                                        <th style={{ width: '100px' }}>Date</th>
                                                        <th style={{ width: '60px' }}>Slot </th>
                                                        <th style={{ width: '70px' }}>Payment</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.centers !== null ?
                                                        <React.Fragment>
                                                            {this.state.centers?.map((item, index) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            <td style={{ fontSize: '14px', lineHeight: '18px' }}>
                                                                                <AnimatedOnScroll animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}> {item.name}  {item.start_date !== 'TBD' ? <span className='newTag'>New</span> : ''} </AnimatedOnScroll> </td>
                                                                            <td> <AnimatedOnScroll animationInDelay={index * 120} animationIn="fadeInUp" isVisible={true}>{item?.duration}</AnimatedOnScroll> </td>
                                                                            <td> <AnimatedOnScroll animationInDelay={index * 120} animationIn="fadeInUp" isVisible={true}>{item.start_date}</AnimatedOnScroll> </td>
                                                                            <td> <AnimatedOnScroll animationInDelay={index * 160} animationIn="fadeInUp" isVisible={true}>{item.batch_days}</AnimatedOnScroll> </td>
                                                                            <td>  <AnimatedOnScroll animationInDelay={index * 140} animationIn="fadeInUp" isVisible={true}>
                                                                                {item.end_date === 'https://www.google.com' ? 
                                                                                <button className='btn btn-primary btn-sm' disabled={item.end_date === 'https://www.google.com'}>
                                                                                    Pay Now</button> : <a aria-disabled={true} className='btn btn-primary btn-sm' rel="noopener noreferrer" target='_blank' href={item.end_date}>Pay Now</a>}
                                                                   
                                                                            </AnimatedOnScroll> </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <tr>
                                                                <td colSpan="5"> No Batch Available </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <CoursesComp></CoursesComp>
                <WhyUs></WhyUs>
                <Testimonials></Testimonials>
                {/* <Instructors></Instructors> */}
                {/* <div className='blog_resource'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>Resources</h2>
                            </div>
                            <div className='col-md-6 mb-3'><FreeResources></FreeResources></div>
                            <div className='col-md-6 mb-3'><Blog></Blog></div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        );
    }
}
