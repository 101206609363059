/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ContactForm from '../../components/website/contactForm';
import WhyUs from '../../components/WhyUs';
import MobileNumbers from '../../components/website/MobileNumbers';
import ContactUsNumber from '../../components/ContactUsNumber'
import PhoneNumber from '../../components/PhoneNumber';

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Contact Us"></SubHeader>

                <div className="section aboutus">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-sm-12 text-left">
                                <p>We would love to provide you with more information and answer any questions that you might have. We look forward to hearing from you.</p>
                                <p>&nbsp;</p>

                                <div className="row justify-content-center">
                                    <div className="col-md-5 col-sm-12 text-left">
                                        <div className='inner'>
                                            <h3>Get In Touch</h3>
                                            <p>Drop us a line, someone from our team will get in touch with you shortly.</p>
                                            <ContactForm></ContactForm>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-center">
                                        <div className="contact_info">
                                            <div className="text-left">
                                                <h3>Contact Info</h3>
                                                <p><span className="ti-email iconSpan"></span>  Email : <a href="mailto:info@vctcpune.com" title="info@vctcpune.com">info@vctcpune.com</a> </p>
                                                <PhoneNumber></PhoneNumber>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id='whyUsSection' className='contactbox' style={{ background: '#fff' }}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12'>
                            <h1>Contact us</h1>
                                <ContactUsNumber></ContactUsNumber>
                            </div>
                        </div>
                    </div>
                </div> */}
                <WhyUs></WhyUs>
            </React.Fragment>
        );
    }
}
