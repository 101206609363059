import React from "react"

const PhoneNumber = () => {
    return (
        <>
            <ul className='phone_list'>
                <li><a href="tel:7620245847">7620245847</a></li>
                <li><a href="tel:9322103532">9322103532</a></li>
                <li><a href="tel:9422761663">9422761663</a></li>
                <li><a href="tel:8010867086">8010867086</a></li>
                <li><a href="tel:8459402118">8459402118</a></li>
            </ul>
        </>
    )
}

export default PhoneNumber