/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';

export default class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Page not found"></SubHeader>                
            </React.Fragment>
        );
    }
}
