/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import CourseItem from './courseItem';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DefaultImage from '../../images/default2.jpg';
import LinkItem from '../LinkItem';
import { NavLink } from 'react-router-dom';
import { AnimatedOnScroll } from "react-animated-css-onscroll";

export default class CoursesComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }

    async componentDidMount() {

        this.getAllCMS();

    }

    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0&sortBy=order_id=-1')
            .then(res => {
                console.log("Courses res", res)
                this.setState({ tableData: res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className='courseWrapper'>
                    <div className='wrapper'>
                        <div className="row">
                            <div className='courseCategory' style={{ margin: '0px' }}>
                                <div className='wrapper'>
                                    <h2 className="text-center">We offer following courses</h2>
                                    <div className='row'>
                                        {this.state.tableData === null ? '' : this.state.tableData.map((item, index) => {
                                            return (
                                                <React.Fragment>
                                                    <div className='col-md-4 mb-4'>
                                                        <AnimatedOnScroll className="inner" animationInDelay={index * 100} animationIn="fadeIn" isVisible={true}>
                                                            <AnimatedOnScroll animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}> <h3>{item.name}</h3></AnimatedOnScroll>
                                                            <AnimatedOnScroll animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}><p>{item.short_description}</p></AnimatedOnScroll>
                                                            {item?.fees1 !== 'null' && <AnimatedOnScroll animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}><p className='courseDuration'>Duration - {item?.duration}</p></AnimatedOnScroll>}
                                                            <AnimatedOnScroll className="row footerCourse" animationInDelay={index * 100} animationIn="fadeInUp" isVisible={true}>
                                                                {/* <div className='col-sm-12 col-md-6'>
                                                                    <div>
                                                                        <span className='fees'>Fees</span>
                                                                        <span className='amount'>{item?.fees}</span>
                                                                    </div>
                                                                </div> */}
                                                                <div className='col-sm-12 col-md-6 text-left'>
                                                                    <NavLink className={'btn btn-primary'} to={`/courses/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"}>View Courses</NavLink>
                                                                </div>
                                                                <div className='clearfix'></div>
                                                            </AnimatedOnScroll>
                                                        </AnimatedOnScroll>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}