/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeholder from '../../images/placeholder.jpg'
import user_placeholder from '../../images/logo2.jpg';
import userPlaceholder2 from '../../images/user.png';

export default class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }


    async componentDidMount() {

        this.getAllCMS('Testimonials');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Testimonials res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            autoplay: true,
            autoplaySpeed: 8000
        };
        return (
            <React.Fragment>
                <div className="testimonials">
                    <div className="container">
                        <h2 className='text-center'>Testimonials</h2>
                        <h3 className='text-center'>What Our Students Say</h3>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <Slider {...settings}>
                                    {this.state.tableData === null ? '' : this.state.tableData.reverse().map(item => {
                                        return (
                                            <React.Fragment>
                                                <div className="textWrapper text-left">
                                                    <p className='image'> {item.cms_image === 'null' ? <img src={userPlaceholder2} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />} </p>
                                                    <p>{item.cms_title}</p>
                                                    <div dangerouslySetInnerHTML={{ __html: item.cms_description }}></div>
                                                    {item.cms_course_name !== "None" &&
                                                        <>
                                                            <p className='fs16 red p-0 m-0'>Course Name</p>
                                                            <h3>{item.cms_course_name}</h3>
                                                        </>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
