/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import CourseImage from '../../images/course_image.jpg'
import BlogItem from '../../components/website/elementItem';
import axios from 'axios';
import LinkItem from '../../components/LinkItem';
import Placeholder from '../../images/placeholder.jpg'
import { Loader } from '../../components/website/Loader';

export default class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null,
            userLogged: localStorage.getItem("user_user_type") || null
        };
    }

    async componentDidMount() {

        this.getAllCMS('Resources');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("Resources res", res)

                const filteredDates = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
                console.log("filteredDates", filteredDates)
                this.setState({ tableData: filteredDates, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)


            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Free Resources"></SubHeader>
                <div className='wrapperContent'>
                    <div className="container">
                        <div className="row freeVideo">
                            {this.state.tableData === null ?  <Loader></Loader> : this.state.tableData.map(item => {
                                return (
                                    <div className='col-sm-4 mb-3'>
                                        <div className='inner text-center'>
                                            <p className='image'>{item.cms_image === 'undefined' ? <img src={Placeholder} alt="" /> :  item.cms_image === 'null' ? <img src={Placeholder} alt="" />: item.cms_image === null ? <img src={Placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />}</p>
                                            <h3>{item.cms_title}</h3>
                                            <LinkItem variant="primary" to={"/students/free-resouces/" + item.cms_link + "/" + item._id} title={'View Video'}></LinkItem>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
