/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { withRouter } from "react-router";
import { FormControl, MenuItem, Select } from '@material-ui/core';
import WhatsappImage from '../../images/whatsapp.png'


class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            phone: '',
            organization_id: '5f048db166e1c93c780e7e7c',
            discount_table: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDiscount = this.handleChangeDiscount.bind(this);

    }


    handleChangeDiscount(e) {
        const discountSelect = e.target.value;
        console.log('fields', discountSelect);
        this.setState({ discountSelect: discountSelect })
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('handleChange', this.state.fields);

    }

    Subscribe = () => {
        if (this.state.fields.name && this.state.fields.course && this.state.fields.email && this.state.phone) {
            const formData = new FormData();
            formData.append('organization_id', this.state.organization_id);
            formData.append('name', this.state.fields.name);
            formData.append('course', this.state.fields.course);
            formData.append('email', this.state.fields.email);
            formData.append('mobile', this.state.phone);
            formData.append('status', '1');
            formData.append('created_by', 'Website');
            console.log(JSON.stringify(formData));
            axios.post(process.env.react_app_base_url + '/api/v1/frontend/contact_form', formData)
                .then(res => {
                    const link = `https://api.whatsapp.com/send/?phone=919422761663&text=Hi, I am interested in ${this.state.fields.course} course.`
                    window.open(link, '_blank');

                    console.log("res", res)
                    const message = res.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: 'info',
                        autoHideDuration: 5000,
                    });
                    this.props.history.push("/thank-you/")
                    this.setState({
                        fields: {
                            "name": '',
                            "email": '',
                            "mobile": '',
                        },
                        phone: ''
                    })
                })
                .catch(error => {
                    console.log("error.response.status", error)
                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                });
        } else {
            this.props.enqueueSnackbar('Please enter the all details', {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }

    async componentDidMount() {
        this.getAllCMS();
    }

    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0&sortBy=order_id=-1')
            .then(res => {
                console.log("Courses res", res)
                const cfa_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)
                const other_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course !== "CFA");
                console.log("other_menu", other_menu)
                this.setState({ cfa_menu: cfa_menu, discount_table: other_menu, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        console.log("this.props.match.params.title", this.props.match.params.title)
        return (
            <React.Fragment>
                <a className='formLink' target='_blank' rel="noopener noreferrer" href={`https://api.whatsapp.com/send/?phone=919422761663&text=I am interested in ${this.props.match.params.title ?? 'your'} course.`}>
                    Click here to start conversion <img style={{ maxWidth: '30px' }} src={WhatsappImage} />
                </a>
                <h3>OR</h3>
                <p>Fill the form</p>
                <p><TextField name="name" size="small" value={this.state.fields.name} onChange={this.handleChange} id="outlined-basic" label="Name" variant="outlined" /></p>
                <p><TextField name="email" size="small" value={this.state.fields.email} onChange={this.handleChange} id="outlined-basic" label="Email Id" variant="outlined" /></p>
                <p>
                    <label>Course</label>
                    <select onChange={this.handleChange} name="course" className='form-control'>
                        <option value=''>Select Course</option>
                        {this.state.discount_table?.map((item) => {
                            console.log("item", item?.name)
                            return (
                                <option value={item.name}>
                                    {item.name}
                                </option>
                            );
                        })
                        }
                    </select>
                </p>
                <PhoneInput
                    country={'in'}
                    value={this.state.phone}
                    onChange={phone => this.setState({ phone })}
                />

                <p><Button onClick={this.Subscribe} variant="contained" color="primary">Submit</Button></p>
            </React.Fragment>
        );
    }
}


export default withRouter(withSnackbar(ContactForm));