/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { withSnackbar } from 'notistack';
import axios from 'axios';
import BlogItem from '../../components/website/elementItem';
import StudentHeader from '../../components/website/studentHeader';
import Placeholder from '../../images/placeholder.jpg'
import LinkItem from "../../components/LinkItem";
import { Loader } from "../../components/website/Loader";

class StudentsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            cms_description: null,
            cms_type: [],
            cms_category: [],
            type: null,
            cms_details: null,
            api_reps: false,
            cms_description: null,
            action: null,
            tableData: null,
            token: localStorage.getItem('admin_token'),
            organization_id: localStorage.getItem('organization_id'),
            admin_name: localStorage.getItem('admin_name'),
        };
    }

    async componentDidMount() {

        this.getAllCMS('Resources');

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("Resources res", res)
                const filteredDates = res.data.data.sort((a, b) => new Date(b.cms_publish_date) - new Date(a.cms_publish_date))
                this.setState({ tableData: filteredDates, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)

            });
    }

    render() {
        return (
            <React.Fragment>
                <StudentHeader></StudentHeader>
                <div className="student-dashboard">
                    <div className="container">
                        <div className="row freeVideo mb-3">
                            {this.state.tableData === null ? <Loader></Loader> : this.state.tableData.map(item => {
                                return (
                                    <div className='col-sm-4 mb-4'>
                                        <div className='inner text-center'>
                                        <p className='image'>{item.cms_image === 'undefined' ? <img src={Placeholder} alt="" /> :  item.cms_image === 'null' ? <img src={Placeholder} alt="" />: item.cms_image === null ? <img src={Placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />}</p>
                                            <h3>{item.cms_title}</h3>
                                            <LinkItem variant="primary" to={`/students/free-resouces/${item.cms_link}/${item._id}`} title={'View Video'}></LinkItem>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(StudentsDashboard);
