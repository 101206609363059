import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import LinkItem from '../LinkItem';

export class Autocomplete extends Component {
    constructor() {
        super();

        this.state = {
            search: '',
            searchResultShow: false
        };

        this.searchClic = this.searchClic.bind(this)
    }
    

    searchClic(){
        console.log("searchClic")
        this.setState({ searchResultShow: false, })
    }

    searchSpace = (event) => {

        if (event.target.value.length >= 1) {
            console.log("event.target.value", event.target.value.length)
            let keyword = event.target.value;
            this.setState({ search: keyword, searchResultShow: true })

        }
        else {
            console.log("event.target.value", event.target.value.length)
            this.setState({ searchResultShow: false })
        }
    }
    componentDidMount(){
       
    }

    render() {
        console.log("render")
        const styleInfo = {
            paddingRight: '10px'
        }
        const elementStyle = {
            border: 'solid',
            borderRadius: '10px',
            position: 'relative',
            left: '10vh',
            height: '3vh',
            width: '20vh',
            marginTop: '5vh',
            marginBottom: '10vh'
        }
        const items = this.props.options.filter((data) => {
            if (this.state.search == null)
                return data
            else if (data.cms_title.toLowerCase().includes(this.state.search.toLowerCase())) {
                return data
            }
        }).map(data => {
            return (
                <li>
                    <LinkItem to={`/view-blog-details/${data.cms_title.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${data._id.replace(/\s+/g, '-')}` + "/"} title={data.cms_title}></LinkItem>
                </li>
            )
        })

        return (
            <div>
                <TextField
                    name="user_name"
                    onChange={(e) => this.searchSpace(e)}
                    // value={this.state.userInput} 
                    id="outlined-basic"
                    label="Search"
                    variant="outlined" />

                {this.state.searchResultShow === false ? '' : <div className="search-results" onClick={this.searchClic}> <ul> {items} </ul>   </div>}

            </div>
        )
    }

}

export default Autocomplete;
