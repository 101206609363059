/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import icon1 from '../../images/icon1.png';
import icon2 from '../../images/icon2.png';
import icon3 from '../../images/icon3.png';
import USPItem from './uspItem';
import axios from 'axios';

export default class USP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Certificate: null,
            ExpertInstructors: null,
            LearnFromAnywhere: null,

        };
    }

    async componentDidMount() {
        this.getAllPages();
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)

                const Certificate = res.data.data.filter(item => item._id == "5f77428f8e396339ca9b03ce");
                console.log("certificate", Certificate[0])
                this.setState({ Certificate: Certificate[0] })

                const ExpertInstructors = res.data.data.filter(item => item._id == "5f7742ab8e396339ca9b03cf");
                console.log("ExpertInstructors", ExpertInstructors[0])
                this.setState({ ExpertInstructors: ExpertInstructors[0] })

                const LearnFromAnywhere = res.data.data.filter(item => item._id == "5f7742d48e396339ca9b03d0");
                console.log("LearnFromAnywhere", LearnFromAnywhere[0])
                this.setState({ LearnFromAnywhere: LearnFromAnywhere[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                {/* {this.state.Certificate !== null ?
                this.state.ExpertInstructors !== null ?
                this.state.LearnFromAnywhere !== null ?
                    <div className="USPComp">
                        <div className="container-fluid">
                            <div className="row">
                                <USPItem image={this.state.Certificate.image} title={this.state.Certificate.title}
                                    fullcontent={this.state.Certificate.description}
                                    desc={this.state.Certificate.short_description}></USPItem>

                                <USPItem image={this.state.ExpertInstructors.image} title={this.state.ExpertInstructors.title}
                                    fullcontent={this.state.ExpertInstructors.description}
                                    desc={this.state.ExpertInstructors.short_description}></USPItem>

                                <USPItem image={this.state.LearnFromAnywhere.image} title={this.state.LearnFromAnywhere.title}
                                    fullcontent={this.state.LearnFromAnywhere.description}
                                    desc={this.state.LearnFromAnywhere.short_description}></USPItem>
                                    
                            </div>
                        </div>
                    </div>
                    : '': '': ''} */}
            </React.Fragment>
        );
    }
}
