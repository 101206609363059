/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import CourseImage from '../../images/blog-details.jpg';
import Blog from '../../components/website/blog';
import axios from 'axios';
import DefaultImage from '../../images/placeholder.jpg';
import moment from 'moment';
import StudentHeader from '../../components/website/studentHeader';
import { Button } from "@material-ui/core";
import { Loader } from '../../components/website/Loader';

export default class FreeResoucesItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }

    async componentDidMount() {
        console.log("this.props.match.params.id", this.props.match.params.id)
        console.log("this.props.match.params.title", this.props.match.params.title)
        const id = this.props.match.params.id
        this.getAllCMS(id);
    }


    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps.match.params.title)
        console.log("nextProps", nextProps.match.params.id)
        const id = nextProps.match.params.id
        this.getAllCMS(id);

    }

    async getAllCMS(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/' + id)
            .then(res => {
                console.log("Courses res", res)
                // const filteredDates = res.data.data.sort((a, b) => new Date(b.cms_publish_date) - new Date(a.cms_publish_date))
                this.setState({ tableData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <StudentHeader></StudentHeader>
                <div className="student-dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center mb-3">
                                <React.Fragment>
                                    {this.state.tableData == null ?  <Loader></Loader> :
                                        <React.Fragment>


                                            <div className="courseDetails" style={{ margin: '0px' }}>
                                                <div className="container text-left">

                                                    <div className="row justify-content-left">
                                                        <div className="col-md-10">
                                                            <h3>{this.state.tableData.cms_title}</h3>
                                                            <div dangerouslySetInnerHTML={{ __html: this.state.tableData.cms_description }}></div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            {this.state.tableData.resourcefile === null || 
                                                            this.state.tableData.resourcefile === "null" || 
                                                            this.state.tableData.resourcefile.length === 0 ? '' :
                                                                <a className="" href={process.env.react_app_base_url + "/" + this.state.tableData.resourcefile} download>
                                                                    <Button variant="contained" color="primary">Download File</Button> </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12 col-sm-12 text-left">
                                                            <iframe title="Contact map" src={this.state.tableData.cms_tags} allow="autoplay; fullscreen" allowfullscreen></iframe>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
