/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import axios from 'axios';
import CourseCFA from './CourseCFA';
import CertificateCourse from './CertificateCourse';

export default class TopCourseMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfa_menu: null,
            other_menu: null,
        };
    }

    async componentDidMount() {

        this.getAllCMS();

    }
    handleOpen2() {
        this.props.handleOpen2(true)
    }


    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0&sortBy=order_id=-1')
            .then(res => {
                console.log("Courses res", res)
                const cfa_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)
                const other_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course !== "CFA");
                console.log("other_menu", other_menu)
                this.setState({ cfa_menu: cfa_menu, other_menu: other_menu, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        return (
            <React.Fragment>
                {this.props.location === 'footer' ?
                    this.state.other_menu == null ? '' : <CertificateCourse activeClassName={this.props.activeClassName} location={this.props.location} handleOpen2={() => this.handleOpen2()} other_menu={this.state.other_menu}></CertificateCourse>
                    : this.state.other_menu == null ? '' : <li><CertificateCourse activeClassName={this.props.activeClassName}  location={this.props.location} handleOpen2={() => this.handleOpen2()} other_menu={this.state.other_menu}></CertificateCourse></li>
                }
            </React.Fragment>
        );
    }
}
