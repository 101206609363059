/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class DownloadResource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:{},
            phone:'',
            organization_id: '5f048db166e1c93c780e7e7c',
            course_name: this.props.course_name,
            resource_name: this.props.title,
            resource_id: this.props.id,
        };
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }

    Subscribe = () => {

        const formData = new FormData();
        formData.append('organization_id', this.state.organization_id);
        formData.append('name', this.state.fields.name);
        formData.append('email', this.state.fields.email);
        formData.append('mobile', this.state.phone);
        formData.append('course_name', this.state.course_name);
        formData.append('resource_name', this.state.resource_name);
        formData.append('resource_id', this.state.resource_id);
        formData.append('status', '1');
        formData.append('created_by', 'Website');

        console.log(JSON.stringify(formData));

        axios.post(process.env.react_app_base_url + '/api/v1/frontend/resource_download', formData)
            .then(res => {
                console.log("res", res)

                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 7000,
                });
                this.setState({
                    fields: {
                        "name": '',
                        "email": '',
                        "mobile": '',
                    }
                })

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });

    }

    render() {
        return (
            <React.Fragment>
                <h5>Request a Download Free Resouce File</h5>
                <p><TextField name="name" size="small" value={this.state.fields.name} onChange={this.handleChange} id="outlined-basic" label="Name" variant="outlined" /></p>
                <p><TextField name="email" size="small" value={this.state.fields.email} onChange={this.handleChange} id="outlined-basic" label="Email Id" variant="outlined" /></p>
                {/* <p><TextField name="mobile" size="small" value={this.state.fields.mobile} onChange={this.handleChange} id="outlined-basic" label="Mobile" variant="outlined" /></p> */}
                <p> <PhoneInput country={'in'} value={this.state.phone} onChange={phone => this.setState({ phone })} /> </p>
                <p><Button onClick={this.Subscribe} variant="contained" color="primary"> Dowload </Button></p>
            </React.Fragment>
        );
    }
}

export default withSnackbar(DownloadResource);